import { Outlet } from "react-router-dom";
import HeaderTop from "./HeaderTop";
import HelpOffcanvas from "./HelpOffcanvas";
import { useEffect, useState } from "react";
import HeaderNavigation from "./HeaderNavigation";
import { Cookies } from "react-cookie";
import APIRequest from "../helpers/CreateRequest";

const JVSTUSER = '1'

export default function Layout() {
  const [helpOffcanvasOpen, setHelpOffcanvasOpen] = useState(false)
  const [isJVSpecUser, setIsJVSpecUser] = useState(true)


  useEffect(() => {
    const getUserDataToken = async () => {
      try {
        const result = await new APIRequest("/auth/decode-user-data-token", "POST", null, { userDataToken: new Cookies().get("userDataToken") }).GenerateRequest();
        if (result.status === 200) {
          const userData = (await result.json()).userData
          if (userData.UserTypeID !== JVSTUSER && new Cookies().get("userType") !== 'jvst') setIsJVSpecUser(false)
          else setIsJVSpecUser(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getUserDataToken()
  }, [])

  return (
    <>
      <div className=" h-screen overflow-hidden">
        <div className="flex-row">
          <HeaderTop helpOffcanvasOpen={helpOffcanvasOpen} setHelpOffcanvasOpen={setHelpOffcanvasOpen} isJVSpecUser={isJVSpecUser} setIsJVSpecUser={setIsJVSpecUser} />
          {!isJVSpecUser && <HeaderNavigation />}
        </div>
        <div className={`grid ${isJVSpecUser ? 'h-[calc(100%-60px)]' : 'h-[calc(100%_-100px)]'} ${helpOffcanvasOpen ? 'grid-cols-[auto_400px]' : 'grid-cols-[auto_0px]'}  `}>
          <div className="overflow-y-auto overflow-x-hidden bg-md-background h-full">
            <Outlet />
          </div>
          <HelpOffcanvas isOpen={helpOffcanvasOpen} setIsOpen={setHelpOffcanvasOpen} />
        </div>
      </div>
    </>
  );
}
