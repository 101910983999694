import { ChangeEvent, useState } from "react";
import APIRequest from "../../helpers/CreateRequest";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import SignInContainer from "./SignInContainer";
import Input from "./Input";
import Button from "./Button";

//JSR 6/13/23: Made large changes to make style and value changes with state instead of grabbing element
export default function SignIn(): JSX.Element {
  const [, setCookies] = useCookies();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailInputStlye, setEmailInputStyle] = useState({});
  const [passwordInputStyle, setPasswordInputStyle] = useState({});
  const [isError, setIsError] = useState<string | false>(false)
  const [isLoading, setIsLoading] = useState(false)


  function inputHandler(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "email") setEmail(e.target.value)
    else if (e.target.name === "password") setPassword(e.target.value)
  }

  function checkInputs(): boolean {
    let isValid = true;

    if (email === "" || password === "") {
      if (email === "") {
        setEmailInputStyle({ border: "2px solid #f85757" });
      } else {
        setEmailInputStyle({})
      }
      if (password === "") {
        setPasswordInputStyle({ border: "2px solid #f85757", });
      }

      isValid = false;
    } else {
      setEmailInputStyle({});
      setPasswordInputStyle({});
    }

    return isValid;
  }

  async function signIn(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()
    try {
      let isJVSpec = false
      setIsLoading(true)
      const isValid = checkInputs();
      if (!isValid) return;
      if (email.endsWith('jvst') || email === 'alantrellis@earthlink.net') {
        isJVSpec = true
      }


      const result = await new APIRequest("/auth/authenticate", "POST", null, { email: email.replace('jvst', ''), password: password }).GenerateRequest();

      if (result.status === 200) {
        //set up user settings
        const body = await result.json()
        setCookies('settings', body.tenantSettings, {path: '/'}); // set settings cookie
        setCookies("token", body.token, {path: '/'}); // set token cookie
        setCookies("userDataToken", body.userDataToken, {path: '/'}); // set user data token cookie
        if (isJVSpec) {
          setCookies("userType", 'jvst')
          navigate("/app/joint-venture-spec-tool");
        }
        else {
          setCookies("userType", 'forecast', {path: '/'}); // set user type cookie
          navigate("/app/home");
        }
      } else throw result.status
    } catch (err) {
      console.log(err)
      let message = "Unable to process request"
      if (typeof err === 'number' && err === 400) message = "The email or password is not valid"
      setIsError(message)

    } finally {
      setIsLoading(false)
    }
  }

  return (
    <SignInContainer>
      <form onSubmit={signIn} className="flex flex-col gap-y-2 items-center">
        <div className="text-2xl font-semibold mt-2">Sign in</div>
        <Input
          placeholder="Email"
          type="email"
          name="email"
          onChange={inputHandler}
          style={emailInputStlye}
          autoFocus
        />
        <Input
          placeholder="Password"
          type="password"
          name="password"
          onChange={inputHandler}
          style={passwordInputStyle}
        />

        {isError && !isLoading && <span className="bg-red-100 rounded-lg w-[340px] px-5 py-2 flex flex-nowrap">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ef4444"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" /></svg>
          <p className="text-sm m-auto">{isError}</p>
        </span>}

        {/* submit button */}

        <div className="mt-1">
          <Button
            text="Sign in"
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>

        <div className="flex flex-col gap-y-5">
          <Link to="/forgot-password" className="text-sky-500 hover:text-sky-600 mt-1">Forgot password?</Link>
          <span>Don't have an account? <Link to="/#pricing" state={'GetQuote'} className="text-sky-500 hover:text-sky-600">Click here</Link></span>
        </div>

      </form>
    </SignInContainer>
  );
}
