import { useEffect, useState } from "react";
import APIRequest from "../../helpers/CreateRequest";
import SelectField, { SelectFieldProps } from "../../components/atoms/forms/SimpleFields/SelectField";
import { Currency } from "../../helpers/InputValueConverter";
import { mode } from "../../Types/FormTypes";
import { DERIVATIVEFORM } from "./SalesForecasts";
import { SalesForecast } from "../../Types/SalesForecast";

interface SalesPlanProps extends SelectFieldProps {
    form: SalesForecast
    unitsRemainingOriginal: string
}

export default function SalesPlanID(props: SalesPlanProps) {

    

    const getSalesPlanUnits = async () => {
        try {
            const result = await new APIRequest('/salesPlans/budget-unit-total', 'POST', null, { id: props.value }).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                console.log(body)
                if (body.status === 200) {
                    const recordset = body.recordset[0]
                    const salesPlanBudgetUnitTotal = recordset.SalesPlanBudgetUnitTotal
                    //const currentMonthActualSalesUnitCount = recordset.CurrentMonthActualSalesUnitCount
                    const salesForecastUnitsRemaining = props.form.UnitsRemainingOriginal

                    //console.log(salesPlanBudgetUnitTotal, currentMonthActualSalesUnitCount, salesForecastUnitsRemaining)
                    let values = {}
                
                    values = {
                        UnitsRemaining: String((Number(salesForecastUnitsRemaining) - Number(salesPlanBudgetUnitTotal))),
                    }

                    props.setValue((prev: any) => ({ ...prev, ...values }))
                } else throw body.message
            } else throw result.statusText

        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (props.value) {
            getSalesPlanUnits()
        } else {
            props.setValue((prev: any) => ({ ...prev, UnitsRemaining: props.form.UnitsRemainingOriginal }))
        }
    }, [props.value, props.unitsRemainingOriginal])

    return (
        <SelectField {...props} value={props.value || ''}
            requestDetails={{
                name: 'SalesPlan',
                namePlural: 'SalesPlans',
                path: '/salesPlans/names/filtered',
                method: 'POST',
                body: {CommunityID: props.form.CommunityID, UnitTypeID: props.form.UnitTypeID}
            }}
        />
    )
}