import { useState, useEffect } from 'react'
import APIRequest from "../../../../helpers/CreateRequest"
import Button from '../../../../components/atoms/Button';
import ColoredCell from './ColoredCell';
import { month } from '../../../../Types/FormTypes'

type eventType = 
    'Sale' | 'Start' | 'Settlement'

type eventStatus = 
    'Actual' | 'Scheduled' | 'Estimated'

type monthlyPerformanceRecordset = {
    year: number;
    monthName: month;
    communityNameCode: string;
    houseCode: string;
    eventType: eventType;
    eventStatus: eventStatus;
}[]

type communityHouses = {
    houseCode: string;
    monthName: month;
    eventStatus: eventStatus;    
}

export type monthlyPerformanceCommunity = {
    communityNameCode: string;
    communityHouses: communityHouses[];
}

type monthlyPerformanceCommunities = monthlyPerformanceCommunity[]

export default function MonthlyPerformanceReport({ScenarioID, triggerNewData}: {ScenarioID: string, triggerNewData: boolean}) {
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [eventType, setEventType] = useState<eventType>('Sale')
    const [yearOptions, setYearOptions] = useState<JSX.Element[]>([])
    const [monthlyPerformanceRecordset, setMonthlyPerformanceRecordset] = useState<monthlyPerformanceRecordset>([])
    const [uniqueYears, setUniqueYears] = useState<number[]>([])
    const [tableData, setTableData] = useState<monthlyPerformanceCommunities>([])
    const [selected, setSelected] = useState<eventType>('Sale')

    const handleSelectionBtn = (type: eventType) => {
        setEventType(type)
        setSelected(type)
    }

    const getMonthlyPerformanceRecordset = async () => {

        const tempMonthlyPerformanceRecordset: monthlyPerformanceRecordset = [];

        if (ScenarioID) {
            try {
                const result = await new APIRequest('/scenariosSalesForecastEvents/monthlyPerformance', "POST", null, { scenarioID: ScenarioID} ).GenerateRequest()

                if (result.status === 200) {
                    const body = await result.json()
                    //console.log(body)

                    if (body.status === 200) {

                        for(let i = 0; i < body.recordset.length; i++) {
                            
                            if(body.recordset[i].CommunityNameCode === 'Spring Way (SW)' && body.recordset[i].HouseCode === 'SW001') {
                            
                                //console.log(body.recordset[i].HouseCode + ' ' + body.recordset[i].EventYear + ' ' + body.recordset[i].EventMonth + ' ' + body.recordset[i].EventType + ' ' + body.recordset[i].EventStatus)
                            
                            }

                            tempMonthlyPerformanceRecordset.push({
                                year: body.recordset[i].EventYear,
                                monthName: body.recordset[i].EventMonth,
                                communityNameCode: body.recordset[i].CommunityNameCode,
                                houseCode: body.recordset[i].HouseCode,
                                eventType: body.recordset[i].EventType,
                                eventStatus: body.recordset[i].EventStatus
                            })
                        }
                        setMonthlyPerformanceRecordset(tempMonthlyPerformanceRecordset || []);

                    } else throw body.message
                } else throw result.statusText

                //console.log(monthlyPerformanceRecordset)

            } catch (err) {
                console.log(err)
            }
        }
    
    }

    // get Monthly Performance Items for the selected scenario
    useEffect(() => {

        getMonthlyPerformanceRecordset()

    }, [ScenarioID, triggerNewData])
    
    useEffect(() => {
        if (monthlyPerformanceRecordset[0]) {

            const tempMonthlyPerformanceCommunities: monthlyPerformanceCommunities = [];
            const tempUniqueYears: number[] = [];

            monthlyPerformanceRecordset.forEach((record) => {

                if (!tempUniqueYears.includes(record.year)) {
                    tempUniqueYears.push(record.year)
                }

                if(record.year === year && record.eventType === eventType) {
                    //console.log(record.houseCode)
                    let communityFound = false
                    for (let i = 0; i < tempMonthlyPerformanceCommunities.length; i++) {
                        if (tempMonthlyPerformanceCommunities[i].communityNameCode === record.communityNameCode) {
                            communityFound = true
                            tempMonthlyPerformanceCommunities[i].communityHouses.push({
                                houseCode: record.houseCode,
                                monthName: record.monthName,
                                eventStatus: record.eventStatus
                            })
                            break
                        }
                    }

                    if (!communityFound) {
                        tempMonthlyPerformanceCommunities.push({
                            communityNameCode: record.communityNameCode,
                            communityHouses: [{
                                houseCode: record.houseCode,
                                monthName: record.monthName,
                                eventStatus: record.eventStatus
                            }]
                        })
                    }
                }
            })

            setTableData(tempMonthlyPerformanceCommunities)
            setUniqueYears(tempUniqueYears.sort((a, b) => a - b))
        }
    }, [monthlyPerformanceRecordset, year, eventType])
    
    useEffect(() => {
        if (uniqueYears[0]) {

            const tempYearOptions: JSX.Element[] = []
            for (let i = 0; i < uniqueYears.length; i++) {
                tempYearOptions.push(
                    <option value={uniqueYears[i]} key={`monthlyPerformanceYears${uniqueYears[i]}`}>
                        {uniqueYears[i]}
                    </option>
                )
            }
            setYearOptions(tempYearOptions)

        }
    }, [uniqueYears])

    return (
        <div className="flex w-full flex-col h-full gap-y-4 max-w-[1600px] mx-auto">
            {/* header */}
            <div className="grid grid-cols-12 w-full gap-4 h-[80px] items-center bep-shadow-xl rounded-lg p-4">
                <span className="col-span-3 headline-large">Monthly Performance</span>

                <span className='col-span-3 flex justify-left '>
                    <select className='px-2 py-1 rounded-sm bg-md-surface-variant' onChange={e => setYear(Number(e.target.value))} value={year} >
                        <option value={''}>Select year</option>
                        {yearOptions}
                    </select>
                </span>
            </div>

            {/* body */}
            <div className="flex flex-grow flex-col">

                <div className=' flex w-full gap-y-2 gap-x-4'>
                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg gap-y-3 items-center w-full'>

                        <span className="pb-4 border-b-2 text-center text-xl">Monthly Performance</span>
                        <div className='flex justify-right'>
                            <ColorKey />
                        </div>
                        <div className={`grid grid-cols-12`}>
                            <div className='col-span-4 m-2'>
                                <Button label={'Sale'} styleType={'selection'} type={'button'} action={handleSelectionBtn} actionParams={'Sale'} selected={selected} />
                            </div>
                            <div className='col-span-4 m-2'>
                                <Button label={'Start'} styleType={'selection'} type={'button'} action={handleSelectionBtn} actionParams={'Start'} selected={selected}/>
                            </div>
                            <div className='col-span-4 m-2'>
                                <Button label={'Settlement'} styleType={'selection'} type={'button'} action={handleSelectionBtn} actionParams={'Settlement'} selected={selected}/>
                            </div>

                        </div>
                        <div>
                            <MonthlyPerformanceTable tableData={tableData} year={year} />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

function MonthlyPerformanceTable({ tableData, year }: { tableData: monthlyPerformanceCommunities, year: number }) {
    const [openCommunity, setOpenCommunity] = useState<string>()

    //console.log(tableData);

    function getMonthlyPerformanceTotal(month: month) {

        let total = 0

        tableData.forEach((community) => {
            community.communityHouses.forEach((house) => {
                if (house.monthName === month) {
                    total++
                }
            })
        })

        return total
    }

    function getYearlyPerformanceTotal() {

        let total = 0

        tableData.forEach((community) => {
            community.communityHouses.forEach((house) => {
                total++
            })
        })

        return total
    }

    
    const handleClick = (nameCode: string) => {
        if (openCommunity === nameCode) setOpenCommunity(undefined)
        else setOpenCommunity(nameCode)
    }

    return (
        <div className=" bep-shadow-xl rounded-md max-h py-2 px-4">

            <div className="w-full h-full overflow-auto">
                {/* headers */}
                <div className="grid grid-cols-12 border-b-2 border-gray-300 h-auto w-full sticky top-0 bg-md-background font-bold z-20">
                    {/* Year Header */}
                    <span className="text-center p-2 col-span-3 border-b-2 border-t-2 border-l-2 border-r border-gray-300 text-md font-bold">Year</span>
                    <span className="text-center p-2 col-span-8 border-b-2 border-t-2 border-l border-r border-gray-300 text-md font-bold">{year}</span>
                    <span className="text-center p-2 col-span-1 border-b-2 border-t-2 border-l border-r-2 border-gray-300 text-md font-bold">Total</span>

                    {/* Community Header */}
                    <span className="text-center p-2 col-span-3 border-l-2 border-r border-gray-300">Community</span>
                    
                    {/* Monthly Headers */}
                    <div className="grid grid-cols-12 col-span-8 text-xs">
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">January</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">February</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">March</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">April</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">May</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">June</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">July</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">August</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">September</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">October</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">November</span>
                        <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">December</span>
                    </div>
        
                    {/* Placeholder */}
                    <span className="text-center p-1 border-l border-r-2 border-gray-300"> - </span>
                </div>
    
                {/* rows */}
                <div>
                {
                    tableData
                    .sort((a, b) => a.communityNameCode.localeCompare(b.communityNameCode))
                    .map((community, index) => (
                        <div key={`monthlyPerformanceTable${community.communityNameCode}${index}`}>
                            <div className={`w-full h-min grid grid-cols-12 body-medium cursor-pointer hover:bg-[--md-ref-palette-primary90] ${index % 2 === 0 ? '' : 'bg-gray-100'} `} onClick={() => handleClick(community.communityNameCode)}>
                                <div className="grid grid-cols-6 col-span-3">
                                    <span className="pl-4 pt-1 px-auto border-l-2 border-b-2 border-gray-300 w-full">
                                        <svg className={`flex  transition-transform ${openCommunity === community.communityNameCode ? 'rotate-180' : ''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                                    </span>
                                    <div className="w-full text-start whitespace-nowrap p-2 col-span-5 border-r border-b-2 border-gray-300">
                                        <span>{community.communityNameCode}</span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 col-span-8 font-bold">
                                    <ColoredCell community={community} month={'January'} />
                                    <ColoredCell community={community} month={'February'} />
                                    <ColoredCell community={community} month={'March'} />
                                    <ColoredCell community={community} month={'April'} />
                                    <ColoredCell community={community} month={'May'} />
                                    <ColoredCell community={community} month={'June'} />
                                    <ColoredCell community={community} month={'July'} />
                                    <ColoredCell community={community} month={'August'} />
                                    <ColoredCell community={community} month={'September'} />
                                    <ColoredCell community={community} month={'October'} />
                                    <ColoredCell community={community} month={'November'} />
                                    <ColoredCell community={community} month={'December'} />
                                </div>
                                <span className="text-center p-2 border-l border-r-2 border-b-2 border-gray-300 font-bold"> {community.communityHouses.length} </span>
                            </div>
                            <div className={`grid transition-all ${openCommunity === community.communityNameCode ? `grid-rows-[1fr]` : 'grid-rows-[0fr]'}`}>
                                <div className='overflow-hidden '>
                                    {
                                        community.communityHouses
                                        .sort((a, b) => a.houseCode.localeCompare(b.houseCode))
                                        .map((house, index) => (
                                            <div className='grid grid-cols-12  ' key={`monthlyPerformanceTable${community.communityNameCode}${house.houseCode}`}>
                                                <span className="col-span-2 border-l-2 border-b-2 border-gray-300"></span>
                                                <span className='col-span-1 border-r border-b-2 border-gray-300'>
                                                    {house.houseCode}
                                                </span>
                                                <div className="grid grid-cols-12 col-span-8 border-b-2 border-gray-300">
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'January' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'February' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'March' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'April' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'May' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'June' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'July' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'August' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'September' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'October' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'November' ? 1 : ' '}</span>
                                                    <span className={`text-center font-bold p-2 border-r border-l border-gray-300 ${house.eventStatus === 'Estimated' ? 'text-pale-green' : (house.eventStatus === 'Scheduled' ? 'text-light-blue' : '')}`}>{house.monthName === 'December' ? 1 : ' '}</span>
                                                </div>
                                                <span className='col-span-1 border-l border-r-2 border-b-2 border-gray-300'></span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
                <div>
                {

                    <div className="grid grid-cols-12 border-b-2 border-gray-300 h-auto w-full sticky top-0 bg-gray-200 font-bold z-20">
                        {/* Community Header */}
                        <span className="text-center p-2 col-span-3 border-l-2 border-r border-gray-300">Total</span>
                        
                        <div className="grid grid-cols-12 col-span-8 text-xs">
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('January')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('February')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('March')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('April')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('May')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('June')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('July')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('August')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('September')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('October')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('November')}</span>
                            <span className="text-center pt-3 pr-2 pl-2 border-l border-r border-gray-300">{getMonthlyPerformanceTotal('December')}</span>
                        </div>
            
                        {/* Placeholder */}
                        <span className="text-center pt-2 pr-1 pl-1 border-l border-r-2 border-gray-300"> {getYearlyPerformanceTotal()} </span>
                    </div>
                }
                </div>
                
            </div>

        </div>
    )
}

function ColorKey() {
    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-8">
                <div className="flex gap-2 items-center">
                    <div className="w-4 h-4 bg-pale-green rounded-full"></div>
                    <span>Estimated</span>
                </div>
                <div className="flex gap-2 items-center">
                    <div className="w-4 h-4 bg-light-blue rounded-full"></div>
                    <span>Scheduled</span>
                </div>
            </div>
        </div>
    )
}