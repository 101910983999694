import { useEffect } from "react"
import { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form"

export type CheckboxFieldType = {
    name: string,
    label?: string,
    alias?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    defaultChecked: boolean
}

export default function CheckBoxField({ name, label, alias, disabled, required, value, formRef, defaultChecked, setValue }: CheckboxFieldType) {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formRef.onChange(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        formRef.onBlur(e)
    }

    useEffect(() => {
        if (defaultChecked) {
            setValue(name, value ? value : 'True')
        } else{
            setValue(name, undefined)
        }
    },[defaultChecked, disabled])

    return (

        <div className={`body-large`}>


            <input
                name={name}
                type="checkbox"
                disabled={disabled}
                required={required}
                onChange={handleChange}
                value={value}
                onBlur={handleBlur}
                ref={formRef.ref}
                defaultChecked={defaultChecked}
                className="m-auto"
            />

{
                label ? <label className={` col-span-4 ${disabled ? `${!defaultChecked ? 'opacity-[.38]' : ''} text-md-on-surface body-large` : ''}`} htmlFor={name} >{alias || label}</label> : <></>
            }
        </div>
    )
}