import SelectField, { SelectFieldProps } from "../../components/atoms/forms/SimpleFields/SelectField"
import APIRequest from "../../helpers/CreateRequest"
import { useEffect } from "react"
import { SalesForecast } from "../../Types/SalesForecast"

interface CommunityIDProps extends SelectFieldProps {
    unitTypeID: string | undefined,
    gotObjectCount: number
}

export default function CommunityID(props: CommunityIDProps) {

    const getUnitsRemaining = async (id: string, unitTypeID: string) => {
        try {
            const result = await new APIRequest('/communities/unitsRemaining', 'POST', null, { id: id, unitTypeID: unitTypeID }).GenerateRequest()
            if (result.status !== 200) throw 'Failed to get units remaining'
            const body = await result.json()
            if (body.status === 200) {
                const unitsRemaining = body.recordset[0].UnitsRemaining
                const unitsRemainingOriginal = body.recordset[0].UnitsRemaining
                props.setValue((prev: any) => ({...prev, UnitsRemaining: unitsRemaining || unitsRemaining === 0 ? String(unitsRemaining) : '', UnitsRemainingOriginal: unitsRemainingOriginal || unitsRemainingOriginal === 0 ? String(unitsRemainingOriginal) : ''}))
            } else throw 'Failed to get units remaining'
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (props.value && props.unitTypeID) getUnitsRemaining(props.value, props.unitTypeID)
    }, [props.gotObjectCount])

    return (
        <SelectField {...props} />
    )
}
