import { Fragment, useState, useEffect } from 'react'
import APIRequest from "../../../../helpers/CreateRequest"
import { option } from "../HouseHistoryReport/HouseHistory"
import { month } from '../../../../Types/FormTypes'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { Currency } from "../../../../helpers/InputValueConverter"
import MultiSelectField from "../../../../components/atoms/forms/SimpleFields/MultiSelectField"
import FormatDate from "../../../../helpers/FormatDate"

type eventType = 
    'Sale' | 'Start' | 'Settlement'

type saleStatus = 
    'Projected' | 'Spec' | 'Agreement' | 'Settled'

const monthOrder = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]
const eventTypeOrder = [
    'Sale', 'Start', 'Settlement'
]

type actualPerformanceFilters = {
    community: string[];
    year: string[];
    eventType: string[];
}

type actualPerformanceOptions = {
    community: option[];
    year: option[];
    eventType: option[];
}

type actualPerformanceRecordset = {
    year: string;
    monthName: month;
    eventDate: string;
    saleEventDate: string;
    startEventDate: string;
    settlementEventDate: string;
    communityNameCode: string;
    houseCode: string;
    eventType: eventType;
    saleStatus: saleStatus;
    revenue: number;
    gross: number;
}[]

type barChartData = {
    eventType: eventType;
    [key: string]: any;
}[]

type house = {
    year: string;
    monthName: month;
    communityNameCode: string;
    houseCode: string;
    eventType: eventType;
    eventDate: string;
    saleEventDate: string;
    startEventDate: string;
    settlementEventDate: string;
    saleStatus: saleStatus;
    revenue: number;
    gross: number;
}

type community = {
    houses: house[];
}

type communityMap = Map<string, community>;

type totals = {
    [key: string]: {
        [key: string]: {
            count: number
            revenue: number
            gross: number
        }
    }
}

type dataPoint = { x: string; y: number | null; };
type dataPoints = dataPoint[];
type dataSet = {
    id: string;
    color: string;
    data: dataPoints;
}[]

export default function ActualPerformanceReport({ScenarioID, triggerNewData}: {ScenarioID: string, triggerNewData: boolean}) {
    const currentYear = new Date().getFullYear()
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based
    const currentDay = currentDate.getDate();
    const [actualPerformanceRecordset, setActualPerformanceRecordset] = useState<actualPerformanceRecordset>([])
    const [barChartData, setBarChartData] = useState<barChartData>([])
    const [communityMap, setCommunityMap] = useState<communityMap>(new Map())
    const [totals, setTotals] = useState<{ communityTotals: totals }>({ communityTotals: {} })
    const [lineChartDataSales, setLineChartDataSales] = useState<dataSet>([])
    const [lineChartDataStarts, setLineChartDataStarts] = useState<dataSet>([])
    const [lineChartDataSettlements, setLineChartDataSettlements] = useState<dataSet>([])

    const [filters, setFilters] = useState<actualPerformanceFilters>({
        community: [],
        year: [currentYear.toString() ],
        eventType: ['Sale', 'Start', 'Settlement']
    })
    const [options, setOptions] = useState<actualPerformanceOptions>({
        community: [],
        year: [],
        eventType: []
    })
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleData = (actualPerformanceRecordset: actualPerformanceRecordset) => {

        setOptions(getFilterOptions(actualPerformanceRecordset))

        const tempCommunityMap: communityMap = new Map()
        const tempBarChartData: barChartData = []
        const tempLineChartDataSales: dataSet = []
        const tempLineChartDataStarts: dataSet = []
        const tempLineChartDataSettlements: dataSet = []

        const houses = filterData(actualPerformanceRecordset)

        houses.forEach(house => {

            let yearFound = false

            if (house.eventType === 'Sale') {

                for (let i = 0; i < tempLineChartDataSales.length; i++) {
                    if (tempLineChartDataSales[i].id === house.year) {
                        yearFound = true
                        for (let j = 0; j < tempLineChartDataSales[i].data.length; j++) {
                            if (tempLineChartDataSales[i].data[j].x === house.monthName) {
                                tempLineChartDataSales[i].data[j].y = (tempLineChartDataSales[i].data[j].y ?? 0) + 1
                                break;
                            }
                        }
                    }
                }
                if (!yearFound) {
                    tempLineChartDataSales.push({
                        id: house.year,
                        color: '#33FF57',
                        data: [
                            { x: 'January', y: house.monthName === 'January' ? 1 : null }
                            ,{ x: 'February', y: house.monthName === 'February' ? 1 : null }
                            ,{ x: 'March', y: house.monthName === 'March' ? 1 : null }
                            ,{ x: 'April', y: house.monthName === 'April' ? 1 : null }
                            ,{ x: 'May', y: house.monthName === 'May' ? 1 : null }
                            ,{ x: 'June', y: house.monthName === 'June' ? 1 : null }
                            ,{ x: 'July', y: house.monthName === 'July' ? 1 : null }
                            ,{ x: 'August', y: house.monthName === 'August' ? 1 : null }
                            ,{ x: 'September', y: house.monthName === 'September' ? 1 : null }
                            ,{ x: 'October', y: house.monthName === 'October' ? 1 : null }
                            ,{ x: 'November', y: house.monthName === 'November' ? 1 : null }
                            ,{ x: 'December', y: house.monthName === 'December' ? 1 : null }
                        ]
                    })
                }

            } else if (house.eventType === 'Start') {

                for (let i = 0; i < tempLineChartDataStarts.length; i++) {
                    if (tempLineChartDataStarts[i].id === house.year) {
                        yearFound = true
                        for (let j = 0; j < tempLineChartDataStarts[i].data.length; j++) {
                            if (tempLineChartDataStarts[i].data[j].x === house.monthName) {
                                tempLineChartDataStarts[i].data[j].y = (tempLineChartDataStarts[i].data[j].y ?? 0) + 1
                                break;
                            }
                        }
                    }
                }
                if (!yearFound) {
                    tempLineChartDataStarts.push({
                        id: house.year,
                        color: '#33FF57',
                        data: [
                            { x: 'January', y: house.monthName === 'January' ? 1 : null }
                            ,{ x: 'February', y: house.monthName === 'February' ? 1 : null }
                            ,{ x: 'March', y: house.monthName === 'March' ? 1 : null }
                            ,{ x: 'April', y: house.monthName === 'April' ? 1 : null }
                            ,{ x: 'May', y: house.monthName === 'May' ? 1 : null }
                            ,{ x: 'June', y: house.monthName === 'June' ? 1 : null }
                            ,{ x: 'July', y: house.monthName === 'July' ? 1 : null }
                            ,{ x: 'August', y: house.monthName === 'August' ? 1 : null }
                            ,{ x: 'September', y: house.monthName === 'September' ? 1 : null }
                            ,{ x: 'October', y: house.monthName === 'October' ? 1 : null }
                            ,{ x: 'November', y: house.monthName === 'November' ? 1 : null }
                            ,{ x: 'December', y: house.monthName === 'December' ? 1 : null }
                        ]
                    })
                }
                
            } else if (house.eventType === 'Settlement') {

                for (let i = 0; i < tempLineChartDataSettlements.length; i++) {
                    if (tempLineChartDataSettlements[i].id === house.year) {
                        yearFound = true
                        for (let j = 0; j < tempLineChartDataSettlements[i].data.length; j++) {
                            if (tempLineChartDataSettlements[i].data[j].x === house.monthName) {
                                tempLineChartDataSettlements[i].data[j].y = (tempLineChartDataSettlements[i].data[j].y ?? 0) + 1
                                break;
                            }
                        }
                    }
                }
                if (!yearFound) {
                    tempLineChartDataSettlements.push({
                        id: house.year,
                        color: '#33FF57',
                        data: [
                            { x: 'January', y: house.monthName === 'January' ? 1 : null }
                            ,{ x: 'February', y: house.monthName === 'February' ? 1 : null }
                            ,{ x: 'March', y: house.monthName === 'March' ? 1 : null }
                            ,{ x: 'April', y: house.monthName === 'April' ? 1 : null }
                            ,{ x: 'May', y: house.monthName === 'May' ? 1 : null }
                            ,{ x: 'June', y: house.monthName === 'June' ? 1 : null }
                            ,{ x: 'July', y: house.monthName === 'July' ? 1 : null }
                            ,{ x: 'August', y: house.monthName === 'August' ? 1 : null }
                            ,{ x: 'September', y: house.monthName === 'September' ? 1 : null }
                            ,{ x: 'October', y: house.monthName === 'October' ? 1 : null }
                            ,{ x: 'November', y: house.monthName === 'November' ? 1 : null }
                            ,{ x: 'December', y: house.monthName === 'December' ? 1 : null }
                        ]
                    })

                }
                
            }
        })

        tempLineChartDataSales.sort((a, b) => Number(a.id) - Number(b.id))
        tempLineChartDataSales.forEach(year => {  
            year.data.sort((a, b) => monthOrder.indexOf(a.x) - monthOrder.indexOf(b.x))
        })
        tempLineChartDataStarts.sort((a, b) => Number(a.id) - Number(b.id))
        tempLineChartDataStarts.forEach(year => {  
            year.data.sort((a, b) => monthOrder.indexOf(a.x) - monthOrder.indexOf(b.x))
        })
        tempLineChartDataSettlements.sort((a, b) => Number(a.id) - Number(b.id))
        tempLineChartDataSettlements.forEach(year => {  
            year.data.sort((a, b) => monthOrder.indexOf(a.x) - monthOrder.indexOf(b.x))
        })
        

        setLineChartDataSales(tempLineChartDataSales)
        setLineChartDataStarts(tempLineChartDataStarts)
        setLineChartDataSettlements(tempLineChartDataSettlements)

        // create map that will hold all houses filtered down by community, to house 
        houses.forEach(house => {
            // check if community exists
            const community = tempCommunityMap.get(house.communityNameCode)
            if (community) {
                community.houses.push(house)
            } else {
                tempCommunityMap.set(house.communityNameCode, { houses: [house] })
            }
        })

        // create bar chart data that will hold the count of each event type by year
        houses.forEach((record) => {

                let OnOrBeforeCurrentDate = isOnOrBeforeCurrentDate(record.eventDate)

                if (OnOrBeforeCurrentDate) {
                    let eventTypeFound = false;

                    for (let i = 0; i < tempBarChartData.length; i++) {
                        if (tempBarChartData[i].eventType === record.eventType) {
                            eventTypeFound = true;

                            if (record.year in tempBarChartData[i]) {
                                tempBarChartData[i][record.year]++
                            } else {
                                tempBarChartData[i][record.year] = 1
                                tempBarChartData[i][(record.year + 'Color').toString()] = "hsl(100, 70%, 50%)"
                            }
                        }                        
                    }
                    if (!eventTypeFound) {
                        tempBarChartData.push({
                            eventType: record.eventType,
                            [record.year]: 1,
                            [(record.year + 'Color').toString()]: "hsl(100, 70%, 50%)"
                        })
                    }
                }

        })

        setCommunityMap(tempCommunityMap)
        setTotals(calculateTotals(houses))
        setBarChartData(tempBarChartData)
    }
    const isOnOrBeforeCurrentDate = (date: string) => {

        const formattedDate = FormatDate(date)

        const [month, day, year] = formattedDate.split('/').map(Number); // Parse the date string
        // Compare month and day only
        if (month < currentMonth || (month === currentMonth && day < currentDay)) {
            return true; // The date is before the current month and day
        }
        return false; // The date is not before
    };
    
    const calculateTotals = (houses: house[]) => {
        let communityTotals: totals = {}

        const updateTotals = (totals: any, key: string, eventType: eventType, year: string, house: house) => {
            if (!totals[key]) {
                totals[key] = {}
            }
            if (!totals[key][`${eventType}${year}`]) {
                totals[key][`${eventType}${year}`] = {
                    count: 0,
                    revenue: 0,
                    gross: 0,
                }
            }
            totals[key][`${eventType}${year}`].count++
            totals[key][`${eventType}${year}`].revenue += house.revenue
            totals[key][`${eventType}${year}`].gross += house.gross
        }

        for (let i = 0; i < houses.length; i++) {
            const house = houses[i]
            const year = house.year
            const community = house.communityNameCode
            const eventType = house.eventType

            updateTotals(communityTotals, community, eventType, year, house)
        }
        return { communityTotals: communityTotals }
    }

    const getFilterOptions = (actualPerformanceRecordset: actualPerformanceRecordset) => {
        // get the unique values for each filter
        const tempOptions: actualPerformanceOptions = {
            community: [],
            year: [],
            eventType: []
        }

        actualPerformanceRecordset.forEach(record => {

            if (!tempOptions.community.some(option => option.value === record.communityNameCode)) tempOptions.community.push({ name: record.communityNameCode, value: record.communityNameCode })
            if (!tempOptions.year.some(option => option.value === record.year)) tempOptions.year.push({ name: record.year, value: record.year })
            if (!tempOptions.eventType.some(option => option.value === record.eventType)) tempOptions.eventType.push({ name: record.eventType, value: record.eventType })

        })

        // order the options
        tempOptions.community.sort((a, b) => a.value.localeCompare(b.value))
        tempOptions.year.sort((a, b) => a.value.localeCompare(b.value))
        //tempOptions.eventType.sort((a, b) => a.value.localeCompare(b.value))

        return tempOptions
    }

    // filter the data based on the selected filters
    const filterData = (actualPerformanceRecordset: actualPerformanceRecordset) => {
        let filteredRecords = actualPerformanceRecordset

        // remove houses with the same house code in the same community name
        /*
        filteredRecords = filteredRecords.filter((house, index, self) => {
            return index === self.findIndex(h => h.houseCode === house.houseCode && h.communityNameCode === house.communityNameCode)
        })
        */
        // filter by filters state 
        filteredRecords = filteredRecords.filter(house => {
            if (filters.community.length > 0 && !filters.community.includes(house.communityNameCode)) return false
            if (filters.year.length > 0 && !filters.year.includes(house.year)) return false
            if (filters.eventType.length > 0 && !filters.eventType.includes(house.eventType)) return false
            return true
        })

        return filteredRecords
    }

    const getActualPerformanceRecordset = async () => {

        const tempActualPerformanceRecordset: actualPerformanceRecordset = [];
        
        if (ScenarioID) {
            try {
                const result = await new APIRequest('/scenariosSalesForecastEvents/actualPerformance', "POST", null, { scenarioID: ScenarioID} ).GenerateRequest()

                if (result.status === 200) {
                    const body = await result.json()

                    if (body.status === 200) {

                        for(let i = 0; i < body.recordset.length; i++) {

                            tempActualPerformanceRecordset.push({
                                year: body.recordset[i].EventYear.toString(),
                                monthName: body.recordset[i].EventMonthName,
                                eventDate: body.recordset[i].EventDate,
                                saleEventDate: body.recordset[i].SaleEventDate,
                                startEventDate: body.recordset[i].StartEventDate,
                                settlementEventDate: body.recordset[i].SettlementEventDate,
                                communityNameCode: body.recordset[i].CommunityNameCode,
                                houseCode: body.recordset[i].HouseCode,
                                eventType: body.recordset[i].EventType,
                                saleStatus: body.recordset[i].SaleStatus,
                                revenue: body.recordset[i].TotalSalesPriceAmount,
                                gross: body.recordset[i].GrossProfitAmount
                            })
                        }
                        setActualPerformanceRecordset(tempActualPerformanceRecordset || []);
                        handleData(tempActualPerformanceRecordset);

                        //console.log(tempActualPerformanceRecordset)

                    } else throw body.message
                } else throw result.statusText

            } catch (err) {
                console.log(err)
            }
        }
    
    }

    // get Actual Performance Items for the selected scenario
    useEffect(() => {

        getActualPerformanceRecordset()

    }, [ScenarioID, triggerNewData])
        
    useEffect(() => {
        if (actualPerformanceRecordset) handleData(actualPerformanceRecordset)
    }, [filters])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth); // Trigger a re-render
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    return (
        <div className="flex w-full flex-col h-full gap-y-4 max-w-[1600px] mx-auto">
            {/* header */}
            <div className="w-full h-[88px] gap-6 items-center bep-shadow-xl rounded-lg p-4 flex">
                <span className="headline-large">Actual Performance</span>
                <MultiSelectField name="community" label="Community" setValue={setFilters} size="md" options={options.community} selected={filters?.community || []} sort={sortCommunities} />
                <MultiSelectField name="year" label="Year" setValue={setFilters} size="sm" options={options.year} selected={filters?.year || []} sort={sortYears} />
                <MultiSelectField name="eventType" label="Event Type" setValue={setFilters} size="sm" options={options.eventType} selected={filters?.eventType || []} sort={sortEvents} />
            </div>

            {/* body */}
            <div className="flex flex-grow flex-col">

                <div className=' flex w-full gap-y-2 gap-x-4 h-full'>
                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg items-center w-full h-full'>

                        <div className='flex flex-grow flex-row pt-4 pl-4 pr-4  gap-x-4 items-center justify-center w-full h-full'>
                            <div className='flex flex-col bep-shadow-xl rounded-lg p-2 w-full h-[616px]'>
                                <div className='text-center'>
                                    <span className="text-xl">Sales, Starts and Settlements</span>
                                </div>
                                <div className='text-center'>
                                    <span className="text-sm">Actual Count by Current Calendar Date by Year</span>
                                </div>
                                <ActualPerformanceBarChart data={barChartData} keys={filters.year.map(String)}/>
                            </div>
                            {
                            lineChartDataSales.length !== 0 || lineChartDataStarts.length !== 0 || lineChartDataSettlements.length !== 0 ?

                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                {
                                filters.eventType.includes('Sale') ?
                                
                                <div className={`bep-shadow-xl rounded-lg w-full p-2 ${filters.eventType.length === 1 ? 'h-[616px]' : filters.eventType.length === 2 ? 'h-[300px]' : 'h-[200px]'} ${filters.eventType.includes('Start') && filters.eventType.includes('Settlement') ? 'mb-1' : (!filters.eventType.includes('Start') && filters.eventType.includes('Settlement')) || (filters.eventType.includes('Start') && !filters.eventType.includes('Settlement')) ? 'mb-2' : ''}`}>
                                    <LineChart data={lineChartDataSales} axisLeftLegend="Sales" yFormat='>-.0f' windowWidth={windowWidth} />
                                </div>
                                : <></>
                                }
                                {
                                filters.eventType.includes('Start') ?
                                
                                <div className={`bep-shadow-xl rounded-lg w-full p-2 ${filters.eventType.length === 1 ? 'h-[616px]' : filters.eventType.length === 2 ? 'h-[300px]' : 'h-[200px]'} ${filters.eventType.includes('Sale') && filters.eventType.includes('Settlement') ? 'mt-1 mb-1' : (!filters.eventType.includes('Sale') && filters.eventType.includes('Settlement')) ? 'mb-2' : (filters.eventType.includes('Sale') && !filters.eventType.includes('Settlement')) ? 'mt-2' : ''}`}>
                                    <LineChart data={lineChartDataStarts} axisLeftLegend="Starts" yFormat='>-.0f' windowWidth={windowWidth} />
                                </div>
                                : <></>
                                }
                                {
                                filters.eventType.includes('Settlement') ?
                                <div className={`bep-shadow-xl rounded-lg w-full p-2 ${filters.eventType.length === 1 ? 'h-[616px]' : filters.eventType.length === 2 ? 'h-[300px]' : 'h-[200px]'} ${filters.eventType.includes('Sale') && filters.eventType.includes('Start') ? 'mt-1' : (!filters.eventType.includes('Sale') && filters.eventType.includes('Start')) || (filters.eventType.includes('Sale') && !filters.eventType.includes('Start')) ? 'mt-2' : ''}`}>
                                    <LineChart data={lineChartDataSettlements} axisLeftLegend="Settlements" yFormat='>-.0f' windowWidth={windowWidth}/>
                                </div>
                                : <></>
                                }
                            </div>
                            : <div className="flex flex-grow justify-center items-center w-full h-full">
                                <span className="text-xl">Please select a scenario.</span>
                            </div>
                            }
                        </div>
                        <div className='flex flex-col p-4 rounded-lg gap-y-3 items-center w-full'>
                            <ActualPerformanceTable data={communityMap} filters={filters} options={options} totals={totals}/>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

function ActualPerformanceBarChart ({ data, keys, windowWidth }: {data: barChartData, keys: string[], windowWidth?: number}) {

    return(

    <ResponsiveBar
        data={data}
        keys={keys.sort((a, b) => Number(a) - Number(b))}
        indexBy="eventType"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={keys.map(k => colorFunction(k))}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Event Type',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
    />
    )
}

function ActualPerformanceTable({ data, filters, options, totals }: { data: communityMap, filters: actualPerformanceFilters, options: actualPerformanceOptions, totals: any }) {
    const [openCommunities, setOpenCommunities] = useState<string[]>([])

    const handleClickedObject = (name: string) => {
        if (openCommunities.includes(name)) {
            setOpenCommunities(openCommunities.filter(community => community !== name))
        } else {
            setOpenCommunities([...openCommunities, name])
        }
    }
    
    return (

        <div className={`overflow-auto max-w-full  bep-shadow-xl rounded-lg w-full border-2 mx-auto`}>
        <table id="ActualPerformanceTable" className=" text-nowrap h-full   border-collapse ">
            <thead className="sticky top-0 z-10  ">

                <tr>
                    <th className="sticky left-0 z-10"></th>
                    {
                        (filters.year.length > 0 ? filters : options).year
                        .map(year => {
                            let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType
                            const tempYear = parseInt(typeof year === 'string' ? year : year.value)

                            return (
                                <th colSpan={tempFilters.length * 5} className=" p-2 text-left sticky top-0" key={'tableHeadYear' + tempYear}>{tempYear}</th>
                            )
                        })
                    }
                </tr>

                <tr>
                    <th className="sticky left-0 z-10"></th>
                    {(filters.year.length > 0 ? filters : options).year.map(year => {
                        const tempYear = parseInt(typeof year === 'string' ? year : year.value)
                        let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType

                        return tempFilters
                        .map(event => {
                            let tempEvent = typeof event === 'string' ? event : event.value
                            return <th colSpan={5} key={'TableHeadEvent' + tempYear + tempEvent} className=" p-2 text-left">{tempEvent}</th>
                        })
                    })
                    }
                </tr>

                <tr>
                    <th className="sticky left-0 z-10"></th>
                    {(filters.year.length > 0 ? filters : options).year.map(year => {
                        let tempYear = parseInt(typeof year === 'string' ? year : year.value)

                        let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType

                        return (
                            tempFilters.map(event => {
                                let tempEvent = typeof event === 'string' ? event : event.value

                                return (
                                    <Fragment key={'tableHeadDataHeaders' + tempYear.toString() + tempEvent}>
                                        <th className="p-2">Count</th>
                                        <th className="p-2">Revenue</th>
                                        <th className="p-2">Gross</th>
                                        <th className="p-2">Date</th>
                                        <th className="p-2">Sale Status</th>
                                    </Fragment>
                                )
                            })
                        )
                    })
                    }
                </tr>
            </thead>

            <tbody>
                {
                    data ?

                        [...data.keys()]
                        .sort((a, b) => a.localeCompare(b))
                        .map((community, comIndex) => {

                            return <Fragment key={'community' + community + comIndex}>
                                <tr className="even:bg-gray-200  odd:bg-white">
                                    <td className="hover:bg-blue-100  p-1 cursor-pointer text-left font-bold sticky left-0 bg-inherit" onClick={() => handleClickedObject(community)}>{openCommunities.includes(community) ? '-' : '+'} {community}</td>

                                    {
                                        (filters.year.length > 0 ? filters : options).year.map(year => {
                                            let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType
                                            let tempYear = parseInt(typeof year === 'string' ? year : year.value)

                                            return tempFilters.map(event => {
                                                let tempEvent = typeof event === 'string' ? event : event.value
                                                let communityTotals = totals.communityTotals[community][`${tempEvent}${tempYear}`] || {}

                                                return <Fragment key={'communityData' + community + tempYear + tempEvent}>
                                                    <td className="table-cell">{communityTotals.count}</td>
                                                    <td className="table-cell">{Currency(communityTotals.revenue)}</td>
                                                    <td className="table-cell">{Currency(communityTotals.gross)}</td>
                                                    <td className="table-cell"></td>
                                                    <td className="table-cell"></td>
                                                </Fragment>
                                            })
                                        })
                                    }
                                </tr>

                                {
                                    openCommunities.includes(community) && (data.get(community)?.houses || [])
                                    .filter((house, index, self) => 
                                        index === self.findIndex(h => 
                                            h.houseCode === house.houseCode && 
                                            h.communityNameCode === house.communityNameCode
                                        ))
                                    .sort((a, b) => a.houseCode.localeCompare(b.houseCode))
                                    .map(house => (
                                        <HouseRow key={'house' + community + house.houseCode} house={house} filters={filters} options={options} community={community} />
                                    ))
                                }
                            </Fragment>
                        })

                        :
                        <tr className="text-center font-bold h-20 m-auto">
                            <td></td>
                            <td className="w-full text-center">Please select a scenario</td>
                            <td></td>
                        </tr>
                }
            </tbody>
        </table>
    </div>
)
}

type HouseRowProps = {
    house: house;
    filters: actualPerformanceFilters;
    options: actualPerformanceOptions;
    community: string;
};

function HouseRow({ house, filters, options, community }: HouseRowProps) {

    return (
        <tr className="even:bg-gray-200 odd:bg-white">
            <td className="hover:bg-blue-100 pl-10 text-left sticky left-0 bg-inherit">{house.houseCode}</td>
            {
                (filters.year.length > 0 ? filters : options).year.map(year => {
                    let tempYear = parseInt(typeof year === 'string' ? year : year.value)
                    let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType

                    const validSale = new Date(house.saleEventDate).getFullYear() === tempYear
                    const validStart = new Date(house.startEventDate).getFullYear() === tempYear
                    const validSettlement = new Date(house.settlementEventDate).getFullYear() === tempYear

                    return tempFilters.map(event => {
                        let tempEvent = typeof event === 'string' ? event : event.value
                        const validHouse = (tempEvent === 'Sale' ? validSale : tempEvent === 'Start' ? validStart : tempEvent === 'Settlement' ? validSettlement : false)

                        return <Fragment key={'houseData' + community + house.houseCode + tempYear + tempEvent}>
                            <td className="table-cell">{validHouse ? 1 : ''}</td>
                            <td className="table-cell">{validHouse ? Currency(house.revenue) : ''}</td>
                            <td className="table-cell">{validHouse ? Currency(house.gross) : ''}</td>
                            <td className="table-cell">{validHouse && tempEvent === 'Sale' ? FormatDate(house.saleEventDate) : validHouse && tempEvent === 'Start' ? FormatDate(house.startEventDate) : validHouse && tempEvent === 'Settlement' ? FormatDate(house.settlementEventDate) : ''}</td>
                            <td className="table-cell">{validHouse ? house.saleStatus : ''}</td>
                        </Fragment>
                    })
                })}
        </tr>
    )
}

function LineChart({ data, axisLeftLegend, yFormat, windowWidth }: { data: dataSet, axisLeftLegend: string, yFormat: string, windowWidth?: number }) {

    return (
        <ResponsiveLine
            key={windowWidth}
            data={data}
            margin={{ top: 30, right: 120, bottom: 70, left: 80 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 15,
                stacked: false,
                reverse: false,
            }}
            yFormat={yFormat}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
                legend: 'Year',
                legendOffset: 65,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisLeftLegend,
                legendOffset: -70,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            colors={data.map(d => colorFunction(d.id))}
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            enablePoints={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: true,
                    translateX: 100,
                    translateY: 50,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 50,
                    itemHeight: 15,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

const colorFunction = (id: string): string => {
    const colors: Record<string, string> = {
        '2010': '#E63946', // Red
        '2011': '#F1FAEE', // Light Mint
        '2012': '#F1C40F', // Bright Yellow
        '2013': '#F39C12', // Bright Orange
        '2014': '#2ECC71', // Green
        '2015': '#1F77B4', // Blue
        '2016': '#9B59B6', // Purple
        '2017': '#D35400', // Dark Orange
        '2018': '#16A085', // Teal
        '2019': '#34495E', // Dark Gray
        '2020': '#E67E22', // Pumpkin Orange
        '2021': '#2980B9', // Light Blue
        '2022': '#C0392B', // Crimson Red
        '2023': '#8E44AD', // Medium Purple
        '2024': '#27AE60', // Emerald Green
        '2025': '#D9A44D', // Gold
        '2026': '#F5B041', // Soft Orange
        '2027': '#7D3C98', // Purple
        '2028': '#F39C12', // Bright Yellow-Orange
        '2029': '#BDC3C7', // Light Gray
    };        return colors[id] || '#000'; // Default color if ID not found
};

const sortYears = (years: string[]): string[] => {
    return years.sort((a, b) => Number(a) - Number(b))
}

const sortEvents = (events: string[]): string[] => {

    const eventTypeOrder = [
        'Sale', 'Start', 'Settlement'
    ]
    
    return events.sort((a, b) => eventTypeOrder.indexOf(a) - eventTypeOrder.indexOf(b))
}
const sortCommunities = (communities: string[]): string[] => {
    return communities.sort((a, b) => a.localeCompare(b))
}