import { Currency } from "../../helpers/InputValueConverter";
import { JVSpec, JVSpecCalculations, JVSTUserDetails } from "./JVSpecTypes";

export function ProjectSummaryBody({ form, calculations, handleInputChange }: { form: JVSpec, calculations?: JVSpecCalculations, handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void }) {

    return (
        <div id="report" className="overflow-auto">
            {/* Deal Name */}

            {/* General */}
            <SectionHeader text="General" />
            <SectionContainer>
                <>
                    <LabelCell text="Project Name" colspan={1} />
                    <span className="col-span-5">  <DataCell name="ProjectName" handleChange={handleInputChange} value={form.ProjectName} type="text" /></span>
                    <LabelCell text="Description" colspan={1} />
                    <span className="col-span-5 ">   <DataCell name="Description" handleChange={handleInputChange} value={form.Description} type="text" /></span>

                    <LabelCell text="Project Address" colspan={1} />
                    <span className="col-span-5"><DataCell name="ProjectAddress" handleChange={handleInputChange} value={form.ProjectAddress} type="text" /></span>
                </>
            </SectionContainer>

            {/* Project Schedule */}
            <SectionHeader text="Project Schedule" />
            <SectionContainer>
                <>
                    <LabelCell text="Pre-Construction Period" colspan={3} />
                    <DataCell name="PreConstructionPeriod" value={form.PreConstructionPeriod} handleChange={handleInputChange} type="number" />

                    <LabelCell text="Construction Period" colspan={3} />
                    <DataCell name="ConstructionPeriod" value={form.ConstructionPeriod} handleChange={handleInputChange} type="number" />

                    <LabelCell text="Sale Period" colspan={3} />
                    <DataCell name="SalePeriod" value={form.SalePeriod} handleChange={handleInputChange} type="number" />
                </>
            </SectionContainer>

            {/* Revenue */}
            <SectionHeader text="Product and Revenue" />
            <SectionContainer>
                <>
                    <LabelCell text="House Estimated Sell Price" colspan={5} />
                    <DataCell name="HouseEstimatedSellPrice" value={form.HouseEstimatedSellPrice} handleChange={handleInputChange} type="currency" />

                </>
            </SectionContainer>

            {/* Costing */}
            <SectionHeader text="Project Costs" />
            <SectionContainer>
                <>
                    {/* Acquisition */}
                    <SectionHeader text="Acquisition" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Land Purchase" colspan={3} />
                            <DataCell name="LandPurchase" value={form.LandPurchase} handleChange={handleInputChange} type="currency" />
                        </>
                    </SectionContainer>

                    {/* Planning and Permitting */}
                    <SectionHeader text="Planning and Permitting" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Design / Planning" colspan={3} />
                            <DataCell name="DesignPlanning" value={form.DesignPlanning} handleChange={handleInputChange} type="currency" />
                        </>
                    </SectionContainer>

                    {/* Site improvements */}
                    <SectionHeader text="Site Improvements" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Site Improvements" colspan={3} />
                            <DataCell name="SiteImprovements" value={form.SiteImprovements} handleChange={handleInputChange} type="currency" />
                        </>
                    </SectionContainer>

                    {/* Direct Construction costs */}
                    <SectionHeader text="Direct Construction Costs" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Direct Construction Costs" colspan={4} />
                            <DataCell name="DirectConstruction" value={form.DirectConstruction} handleChange={handleInputChange} type="currency" />
                        </>
                    </SectionContainer>

                    {/* Contingency  */}
                    <SectionHeader text="Contingency" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Contingency" percent={form.ContingencyPercent} colspan={3} inputPercent={{ name: 'ContingencyPercent', handleChange: handleInputChange }} />
                            <DataCell value={calculations?.Contingency} />


                        </>
                    </SectionContainer>

                    {/* Sales and Marketing */}
                    <SectionHeader text="Sales and Marketing" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Commissions" percent={form.CommissionsPercent} colspan={3} inputPercent={{ name: 'CommissionsPercent', handleChange: handleInputChange }} />
                            <DataCell value={calculations?.Commissions} />
                        </>
                    </SectionContainer>

                    {/* Disposition */}
                    <SectionHeader text="Disposition" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Transfer Taxes and Other Disposition" colspan={3} />
                            <DataCell name="TransferTaxesAndOtherDisposition" value={form.TransferTaxesAndOtherDisposition} handleChange={handleInputChange} type="currency" />
                        </>
                    </SectionContainer>


                    {/* Financing */}
                    <SectionHeader text="Financing" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Commercial Finance Costs" percent={calculations?.CommercialFinanceCostsPercent} colspan={3} />
                            <DataCell name="CommercialFinanceCosts" value={form.CommercialFinanceCosts} handleChange={handleInputChange} type="currency" />

                            <LabelCell text="Construction Interest" percent={form.CommercialBorrowingInterestRatePercent} colspan={3} inputPercent={{ name: 'CommercialBorrowingInterestRatePercent', handleChange: handleInputChange }} />
                            <DataCell name="ConstructionInterest" value={calculations?.ConstructionInterest} />

                            <LabelCell text="Loan Amount" colspan={3} />
                            <DataCell value={calculations?.LoanAmount} />


                            <LabelCell text="Loan to Value" percent={calculations?.LoanToValuePercent} colspan={6} />

                            <LabelCell text="Total Financing Costs" colspan={4} />
                            <DataCell value={calculations?.TotalFinancingCosts} />

                        </>
                    </SectionContainer >

                    <div className="mb-2 grid grid-cols-6 col-span-6 border-b-2  font-bold">
                        <LabelCell text="Total Costs" colspan={5} />
                        <DataCell value={calculations?.TotalCosts} />
                    </div>

                    {/* Other Project Expenses */}
                    <SectionHeader text="Other Project Expenses" />
                    <SectionContainer>
                        <>
                            <LabelCell text="Other Project Expenses" colspan={4} />
                            <DataCell name="OtherProjectExpenses" value={form.OtherProjectExpenses} handleChange={handleInputChange} type="currency" />
                        </>
                    </SectionContainer>
                </>
            </SectionContainer>

            {/* Gross Profit */}
            <div className="mb-4 grid grid-cols-6 font-bold border-y-2 mt-1">
                <LabelCell text="Gross Profit" colspan={5} />
                <DataCell value={calculations?.GrossProfit} />
            </div>

            {/* Deal Expenses */}
            <div className="font-bold grid grid-cols-6 border-b-2">
                <h2 className="col-span-4">Deal Expenses</h2>
                <div className="text-center">Investor</div>
                <div className="text-center">Builder</div>
            </div>
            <SectionContainer>
                <>
                    {/* <LabelCell text="Project Management Fee" percent={form.ProjectManagementRate} colspan={5} /> */}
                    <LabelCell text="Project Management Fee" percent={form.ProjectManagementRate} colspan={5} inputPercent={{ name: 'ProjectManagementRate', handleChange: handleInputChange }} />
                    <DataCell value={calculations?.ProjectManagementFee} />

                    <LabelCell text="Interest on Investment Cash to Investor" percent={form.RateForInvestorCash} colspan={4} inputPercent={{ name: 'RateForInvestorCash', handleChange: handleInputChange }} />
                    <DataCell value={calculations?.InterestOnInvestmentCashToInvestor} />

                    <LabelCell text="Total Deal Expenses" colspan={3} />
                    <DataCell value={calculations?.TotalDealExpenses} />
                </>
            </SectionContainer>

            {/* Net Income */}
            <div className="mb-4 grid grid-cols-6 font-bold border-y-2 mt-1 ">
                <LabelCell text="Net Income" colspan={5} />
                <DataCell value={calculations?.NetIncome} />
            </div>

            {/* Capital Stack */}
            <div className="font-bold grid grid-cols-6 border-b-2">
                <h2 className="col-span-4">Capital Stack</h2>
                <div className="text-center">Investor</div>
                <div className="text-center">Builder</div>
            </div>
            <SectionContainer>
                <>
                    <LabelCell text="Total Cash" colspan={4} />
                    <DataCell name="InvestorCash" value={form.InvestorCash} handleChange={handleInputChange} type="currency" />
                    <DataCell name="BuilderCash" value={form.BuilderCash} handleChange={handleInputChange} type="currency" />
                    <LabelCell text="Equity" colspan={4} />
                    <DataCell value={calculations?.InvestorEquityPercent} isPercent />
                    <DataCell value={calculations?.BuilderEquityPercent} isPercent />

                    <LabelCell text="Minimum IRR" colspan={6} percent={form.MinimumIRR} inputPercent={{ name: 'MinimumIRR', handleChange: handleInputChange }} />

                    <LabelCell text="Maximum IRR" colspan={6} percent={form.MaximumIRR} inputPercent={{ name: 'MaximumIRR', handleChange: handleInputChange }} />
                </>
            </SectionContainer>


            {/* Distributions */}
            <SectionHeader text="Distributions" />
            <SectionContainer>
                <>
                    <LabelCell text="Split above Income Level #1 (Base Return)" colspan={4} />
                    <DataCell value={calculations?.InvestorBaseReturnPercent} isPercent />
                    <DataCell value={calculations?.BuilderBaseReturnPercent} isPercent />

                    <LabelCell text="Distribution Amount" colspan={3} />
                    <DataCell value={calculations?.DistributionAmountOne} />
                    <DataCell value={calculations?.InvestorDistributionAmountOne} />
                    <DataCell value={calculations?.BuilderDistributionAmountOne} />

                    <LabelCell text="New Balance" colspan={3} />
                    <DataCell value={calculations?.BalanceAfterDistributionOne} />

                    <LabelCell text="New IRR" colspan={4} />
                    <DataCell value={calculations?.IRRAfterDistributionOne} isPercent />

                    <div className="w-full border-b col-span-6"></div>

                    <LabelCell text="Split above Income Level #2 (Growth Return)" colspan={4} />
                    <DataCell value={calculations?.InvestorGrowthReturnPercent} isPercent />
                    <DataCell value={calculations?.BuilderGrowthReturnPercent} isPercent />

                    <LabelCell text="Distribution Amount" colspan={3} />
                    <DataCell value={calculations?.DistributionAmountTwo} />
                    <DataCell value={calculations?.InvestorDistributionAmountTwo} />
                    <DataCell value={calculations?.BuilderDistributionAmountTwo} />

                    <LabelCell text="New Balance" colspan={3} />
                    <DataCell value={calculations?.BalanceAfterDistributionTwo} />

                    <LabelCell text="New IRR" colspan={4} />
                    <DataCell value={calculations?.IRRAfterDistributionTwo} isPercent />

                    <div className="w-full border-b col-span-6"></div>

                    <LabelCell text="Split above Income Level #3 (Performance Return)" colspan={4} />
                    <DataCell value={calculations?.InvestorPerformanceReturnPercent} isPercent />
                    <DataCell value={calculations?.BuilderPerformanceReturnPercent} isPercent />

                    <LabelCell text="Distribution Amount" colspan={3} />
                    <DataCell value={calculations?.DistributionAmountThree} />
                    <DataCell value={calculations?.InvestorDistributionAmountThree} />
                    <DataCell value={calculations?.BuilderDistributionAmountThree} />

                    <LabelCell text="New IRR" colspan={4} />
                    <DataCell value={calculations?.FinalIRR} isPercent />

                    <div className="w-full border-b col-span-6"></div>
                </>
            </SectionContainer>

            {/* Totals  */}
            <div className="font-bold grid grid-cols-6 border-b-2 mt-1">
                <h2 className="col-span-4">Totals</h2>
                <div className="text-center">Investor</div>
                <div className="text-center">Builder</div>
            </div>
            <SectionContainer>
                <>
                    <LabelCell text="Cash Out" colspan={4} />
                    <DataCell value={calculations?.InvestorCashOut} />
                    <DataCell value={calculations?.BuilderCashOut} />

                    <LabelCell text="ROE" colspan={4} />
                    <DataCell value={calculations?.InvestorROE} isPercent />
                    <DataCell value={calculations?.BuilderROE} isPercent={calculations?.BuilderROE === 'N/A' ? false : true} />
                </>
            </SectionContainer>
            <div className="pb-2"></div>
        </div >
    )
}


export function ProjectSummary(props: { form: JVSpec, calculations?: JVSpecCalculations, userDetails: JVSTUserDetails }) {

    return (
        <div id="ProjectSummary" className="text-pretty">
            <header className="grid grid-cols-12 border-b-2 py-2">
                <span className="col-span-3">{props.userDetails.Logo}</span>
                <span className="col-span-9 justify-self-end">{new Date().toLocaleDateString()}</span>

                <span className="col-span-12">{props.userDetails.CompanyName}</span>
                <span className="col-span-12">{props.userDetails.EmailAddress} | {props.userDetails.PhoneNumber}</span>

                <span className="col-span-12 text-center font-bold">Project Lending Opportunity</span>

                <span className="col-span-12">{props.form.ProjectName}</span>
                <span className="col-span-12">{props.form.ProjectAddress}</span>
            </header>

            <ProjectSummaryBody form={props.form} calculations={props.calculations} />

            <section>
                {/* Signatory */}
                <div className="col-span-12 mt-4">{props.userDetails.SignatoryName}</div>
                <div className="col-span-12">{props.userDetails.SignatoryPosition}</div>
                <div className="col-span-12">{props.userDetails.CompanyName}</div>
                <div className="col-span-12">{props.userDetails.PhoneNumber}</div>
                <div className="col-span-12">{props.userDetails.EmailAddress}</div>
            </section>
        </div>
    )
}


function SectionContainer({ children }: { children: JSX.Element }) {
    return (
        <div className="grid grid-cols-6 col-span-6 pl-2 pt-1 gap-y-1 gap-x-1">
            {children}
        </div>
    )
}

function LabelCell({ text, percent, colspan, inputPercent }: { text?: string, percent?: string, colspan: number, inputPercent?: { name: string, handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void } }) {
    const className = `col-span-${colspan}`

    return (
        <span className={className}>{text} {' '}
            {
                inputPercent ? <span>

                    <>{inputPercent && inputPercent.handleChange && <NumberField additionalClasses="max-w-8 min-w-8" name={inputPercent.name} onChange={inputPercent.handleChange} value={`${percent || ''}`} max={100} min={0} />}</>

                    {percent ? '%' : ''}</span>

                    : percent ? <span>({percent}%)</span> : null
            }
        </span>
    )
}

function DataCell({ name, value, handleChange, isPercent, type }: { value?: string | number, isPercent?: boolean, name?: string, handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void, type?: 'number' | 'currency' | 'percent' | 'text' }) {
    return (
        <>
            {
                handleChange && name && type ? (
                    type === 'number' || type === 'percent' ? <NumberField name={name} onChange={handleChange} value={`${value}`} /> :
                        type === 'currency' ? <CurrencyField name={name} onChange={handleChange} value={value || ''} /> :
                            <TextField name={name} onChange={handleChange} value={`${value}`} />
                ) : <span className={"text-right "}>{value}{isPercent && value !== undefined ? '%' : ''}</span>
            }
        </>
    )
}

function SectionHeader({ text }: { text: string }) {
    return (
        <h2 className="font-bold border-b-2 col-span-6 mt-2">{text}</h2>
    )
}

function TextField({ name, onChange, value }: { name: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, value: string }) {
    return (
        <input name={name} className={`outline-none px-1 py-[2px] rounded-sm w-full transition-colors bg-stone-200 focus:bg-stone-300 ${value ? "" : "animate-pulse bg-blue-600/10"} `} onChange={onChange} onBlur={onChange} value={value} />
    )
}

function NumberField({ name, onChange, value, min, max, additionalClasses }: { name: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, value: string, min?: number, max?: number, additionalClasses?: string }) {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'));

        // Allow exceptions to pass through
        if (EXCEPTION) {
            return;
        }

        const input = e.currentTarget;
        const currentValue = input.value;
        const newKey = e.key;

        // Get the selected range (highlighted portion of the text in the input)
        const selectionStart = input.selectionStart || 0;
        const selectionEnd = input.selectionEnd || 0;

        // Calculate the new value as if the new key were pressed (considering selection)
        const newValue =
            currentValue.slice(0, selectionStart) + newKey + currentValue.slice(selectionEnd);

        const newNumber = Number(newValue);

        // Check against min and max only if the new value is a valid number
        const exceedsMax = max !== undefined && !isNaN(newNumber) && newNumber > max;
        const exceedsMin = min !== undefined && !isNaN(newNumber) && newNumber < min;

        // Block input if it exceeds constraints
        if (!/^\d$/.test(newKey) || e.key === ' ' || exceedsMax || exceedsMin) {
            e.preventDefault();
        }
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        onChange(e)
    }

    return (
        <input name={name} className={`text-right outline-none px-1 py-[2px] rounded-sm transition-colors bg-stone-200 focus:bg-stone-300  ${value ? "" : "animate-pulse bg-blue-600/10"} ${additionalClasses}`} onKeyDown={handleKeyDown} onChange={onChange} onBlur={handleBlur} value={value} />
    )
}

function CurrencyField({ name, onChange, value, min, max }: { name: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, value: string | number, min?: number, max?: number }) {

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const changedValue = e.currentTarget.value === '$' ? '' : e.currentTarget.value
        e.target.value = Currency(changedValue)
        onChange(e)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))

        //only digits and the exceptions above will work
        if (!/^\d$/.test(e.key) && (!EXCEPTION || e.key === ' ')) {
            e.preventDefault()
        }
    }

    return (
        <input name={name} className={`text-right outline-none px-1 py-[2px] rounded-sm transition-colors bg-stone-200 focus:bg-stone-300 ${value ? "" : "animate-pulse bg-blue-600/10"}`} onKeyDown={handleKeyDown} onChange={onChange} onBlur={handleBlur} value={value} />
    )
}

