import { useState } from "react";
import { type JVSpecCalculations, type JVSpec } from "./JVSpecTypes";
import { InvestorTermsBody } from "./InvestorTerms";
import { LoanTermsBody } from "./LoanTerms";
import { ProjectSummaryBody } from "./ProjectSummary";

// Tab constansts
const PROJECTSUMMARY = 0
const BUILDERREPORT = 1
const INVESTORREPORT = 2
const LOANREPORT = 3

const tabs = ["Project Summary", "Builder Report", "Investor Report", "Loan Report"];


export default function Report({
    form,
    setForm,
    calculations,
    getCalculations,
    closeIfModal
}: {
    form: JVSpec,
    calculations?: JVSpecCalculations,
    getCalculations: () => Promise<void>,
    setForm: React.Dispatch<React.SetStateAction<JVSpec>>,
    closeIfModal?: () => void
}) {
    const [activeTab, setActiveTab] = useState(PROJECTSUMMARY);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm((formValues: JVSpec) => ({ ...formValues, [name]: value }));

        // Trigger calculations on blur
        if (e.type === "blur") getCalculations();
    };


    return (
        <div className="h-full w-full text-xs">
            {/* close button if modal */}


            {/* Tabs */}
            <div className="flex justify-evenly h-[36px] ">
                <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                {
                    closeIfModal && <div className="flex justify-end border-b-2 box-border border-gray-400">
                        <span className="w-full flex cursor-pointer hover:bg-slate-200"><svg className="m-auto mx-2" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000" onClick={closeIfModal}><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg></span>
                    </div>
                }
            </div>

            {/* Dynamic Component Rendering Based on Tab */}
            <div className="pt-4 overflow-auto px-5 h-[calc(100%-36px)]">
                {activeTab === PROJECTSUMMARY ? (
                    <ProjectSummaryBody form={form} calculations={calculations} handleInputChange={handleInputChange} />
                ) :
                    activeTab === BUILDERREPORT ? (
                        <div className="m-auto text-lg">Builder Report coming soon...</div>
                    ) :
                        activeTab === INVESTORREPORT ? (
                            <InvestorTermsBody form={form} calculations={calculations} />
                        ) :
                            activeTab === LOANREPORT ? (
                                <LoanTermsBody form={form} calculations={calculations} />
                            ) : null
                }
            </div>
        </div>
    );
}

function Tabs({ tabs, activeTab, setActiveTab }: { tabs: string[], activeTab: number, setActiveTab: React.Dispatch<React.SetStateAction<number>> }) {

    return (
        <div className="relative w-full">
            {/* Moving gradient indicator */}
            <div
                className="absolute top-0 z-10 left-0 h-full bg-slate-400/70 text-gray-900 border-b-2 border-gray-700  transition-transform duration-300 ease-in-out"
                style={{
                    width: `${100 / tabs.length}%`, // Indicator width matches tab width
                    transform: `translateX(${activeTab * 100}%)`,
                }}
            ></div>

            {/* Tabs */}
            <div className="flex border-b-2 box-border border-zinc-400 h-full ">
                {tabs.map((tab, index) => (
                    <button
                        key={tab}
                        onClick={() => setActiveTab(index)}
                        className={`flex-1 text-center py-2 h-full text-sm transition-colors duration-200 z-20 ${activeTab === index
                            ? "text-gray-900 font-semibold"
                            : "text-gray-700 hover:bg-slate-200"
                            }`}
                    >
                        {tab}
                    </button>
                ))}
            </div>
        </div>
    );
}
