import { JVSpec, JVSpecCalculations, JVSTUserDetails } from "./JVSpecTypes";

export function LoanTermsBody(props: { form: JVSpec, calculations?: JVSpecCalculations }): JSX.Element {
    return (
        <main className="grid grid-cols-12 gap-y-2 py-3">
            {/* Purpose */}
            <span className="font-bold col-span-2">Purpose</span>
            <div className="grid grid-cols-12 col-span-10">
                <span className="col-span-10">&nbsp;</span>
                <span className="col-span-10">
                    To secure financing for a high-quality, luxury single-family spec home that meets market demand. The project aims to achieve a solid return on investment through careful planning, efficient construction, and thoughtful market positioning.
                </span>
            </div>

            {/* Project Summary */}
            <span className="font-bold col-span-12">Project Summary</span>
            <div className="col-span-2">&nbsp;</div>
            <div className="grid grid-cols-12 col-span-10">
                <span className="col-span-3">Total Project Cost :</span>
                <span className="col-span-9">{props.calculations?.TotalCosts}</span>

                <span className="col-span-3">Estimated Sale Price :</span>
                <span className="col-span-9">{props.form.HouseEstimatedSellPrice}</span>

                <span className="col-span-3">Estimated Gross Profit:</span>
                <span className="col-span-9">{props.calculations?.GrossProfit}</span>

                <span className="col-span-3">Estimated Gross Margin:</span>
                <span className="col-span-9">{props.calculations?.GrossMargin}{props.calculations?.GrossMargin ? '%' : ''}</span>
            </div>

            {/* Project Schedule */}
            <span className="font-bold col-span-12">Project Schedule</span>
            <div className="col-span-2">&nbsp;</div>
            <div className="grid grid-cols-12 col-span-10">
                <span className="col-span-7">Duration to Construction Start (Pre-Con Period):</span>
                <span className="col-span-2 text-right">{props.form.PreConstructionPeriod} {props.form?.PreConstructionPeriod ? props.form.PreConstructionPeriod === '1' ? 'month' : 'months' : ''}</span>

                <span className="col-span-7">Duration of Construction (Construction Period):</span>
                <span className="col-span-2 text-right">{props.form.ConstructionPeriod} {props.form?.ConstructionPeriod ? props.form.ConstructionPeriod === '1' ? 'month' : 'months' : ''}</span>

                <span className="col-span-7">Duration From Completion to Settlement (Sale Period):</span>
                <span className="col-span-2 text-right">{props.form.SalePeriod} {props.form?.SalePeriod ? props.form.SalePeriod === '1' ? 'month' : 'months' : ''}</span>

                <span className="col-span-7">Total Duration:</span>
                <span className="col-span-2 text-right">{props.calculations?.TotalDuration} {props.calculations?.TotalDuration ? props.calculations.TotalDuration === '1' ? 'month' : 'months' : ''}</span></div>

            {/* Cost Summary */}
            <span className="font-bold col-span-12">Cost Summary</span>
            <div className="col-span-2">&nbsp;</div>
            <div className="grid grid-cols-12 col-span-10">
                <span className="col-span-5">Land Purchase :</span>
                <span className="col-span-7">{props.form.LandPurchase}</span>

                <span className="col-span-5">Design & Planning:</span>
                <span className="col-span-7">{props.form.DesignPlanning}</span>

                <span className="col-span-5">Site Improvements:</span>
                <span className="col-span-7">{props.form.SiteImprovements}</span>

                <span className="col-span-5">Direct Construction:</span>
                <span className="col-span-7">{props.form.DirectConstruction}</span>

                <span className="col-span-5">Contingency Fund {props.form.ContingencyPercent ? `(${props.form.ContingencyPercent}%)` : ''}:</span>
                <span className="col-span-7">{props.calculations?.Contingency}</span>

                <span className="col-span-5"></span>
                <span className="col-span-1 border-b-2 pb-2 border-black"></span>
                <span className="col-span-3"></span>

                <span className="col-span-5">Total Construction Costs :</span>
                <span className="col-span-7">{props.calculations?.TotalLandAndConstructionFunds}</span>

                <span className="col-span-5">Selling, Financing & Disposition Costs :</span>
                <span className="col-span-7">{props.calculations?.TotalFinancingCosts}</span>

                <span className="col-span-5"></span>
                <span className="col-span-1 border-b-2 pb-2 border-black"></span>
                <span className="col-span-3"></span>

                <span className="col-span-5">Total Costs :</span>
                <span className="col-span-7">{props.calculations?.TotalCosts}</span>
            </div>

            {/* Financing Assumptions */}
            <span className="font-bold col-span-12">Financing Assumptions</span>
            <div className="col-span-2">&nbsp;</div>
            <div className="grid grid-cols-12 col-span-10">
                <span className="col-span-5">Estimated Loan Amount:</span>
                <span className="col-span-7">{props.calculations?.LoanAmount}</span>

                <span className="col-span-5">Loan-to-Cost (LTC) Ratio:</span>
                <span className="col-span-7">{props.calculations?.LoanToValuePercent}{props.calculations?.LoanToValuePercent ? '%' : ''}</span>

                <span className="col-span-5">Interest Rate :</span>
                <span className="col-span-7">{props.calculations?.CommercialFinanceCostsPercent}{props.calculations?.CommercialFinanceCostsPercent ? '%' : ''}</span>

                <span className="col-span-5">Loan Term :</span>
                <span className="col-span-7">{props.calculations?.TotalDuration} Months</span>

                <span className="col-span-5">Estimated Financing & Transaction Costs :</span>
                <span className="col-span-7">{props.calculations?.TotalFinancingCosts}</span>

                <span className="col-span-5">Borrowing Interest:</span>
                <span className="col-span-7">{props.calculations?.ConstructionInterest}</span>
            </div>
        </main>
    );
}

export default function LoanTerms(props: { form: JVSpec, calculations: JVSpecCalculations, userDetails: JVSTUserDetails }): JSX.Element {

    return (
        <div id="LoanTerms" className="text-pretty" >
            <header className="grid grid-cols-12 border-b-2 py-2">
                <span className="col-span-3">{props.userDetails.Logo}</span>
                <span className="col-span-9 justify-self-end">{new Date().toLocaleDateString()}</span>

                <span className="col-span-12">{props.userDetails.CompanyName}</span>
                <span className="col-span-12">{props.userDetails.EmailAddress} | {props.userDetails.PhoneNumber}</span>

                <span className="col-span-12 text-center">Project Lending Opportunity</span>

                <span className="col-span-12">{props.form.ProjectName}</span>
                <span className="col-span-12">{props.form.ProjectAddress}</span>
            </header>


            <LoanTermsBody form={props.form} calculations={props.calculations} />

            <section>
                {/* Signatory */}
                <div className="col-span-12 mt-4">{props.userDetails.SignatoryName}</div>
                <div className="col-span-12">{props.userDetails.SignatoryTitle}</div>
                <div className="col-span-12">{props.userDetails.CompanyName}</div>
                <div className="col-span-12">{props.userDetails.PhoneNumber}</div>
                <div className="col-span-12">{props.userDetails.EmailAddress}</div>
            </section>

        </div>
    )
}