
export type JVSpec = {
    // General
    ProjectName: string,
    Description: string,
    ProjectAddress: string,
    // schedule
    PreConstructionPeriod: string,
    ConstructionPeriod: string,
    SalePeriod: string
    // product and Revenue
    HouseEstimatedSellPrice: string,

    // project costs 
    // acquisition
    LandPurchase: string,
    // Planning and Permitting
    DesignPlanning: string,
    // Site Improvements
    SiteImprovements: string,
    // direct construction costs
    DirectConstruction: string,
    // contingency
    ContingencyPercent: string,
    // sales and marketing
    CommissionsPercent: string,
    // Disposition
    TransferTaxesAndOtherDisposition: string,
    // Financing
    CommercialFinanceCosts: string,
    CommercialBorrowingInterestRatePercent: string,
    // Other Project expenses
    OtherProjectExpenses: string,

    // Deal expenses
    ProjectManagementRate: string,
    RateForInvestorCash: string,

    // capital stack 
    InvestorCash: string,
    BuilderCash: string,
    MinimumIRR: string,
    MaximumIRR: string
}

export const JVSpecDefaultValues: JVSpec = {
    // General
    ProjectName: "",
    Description: "",
    ProjectAddress: "",
    // schedule
    PreConstructionPeriod: "",
    ConstructionPeriod: "",
    SalePeriod: "",
    // product and Revenue
    HouseEstimatedSellPrice: "",

    // project costs 
    // acquisition
    LandPurchase: "",
    // Planning and Permitting
    DesignPlanning: "",
    // Site Improvements
    SiteImprovements: "",
    // direct construction costs
    DirectConstruction: "",
    // contingency
    ContingencyPercent: "",
    // sales and marketing
    CommissionsPercent: "",
    // Disposition
    TransferTaxesAndOtherDisposition: "",
    // Financing
    CommercialFinanceCosts: "",
    CommercialBorrowingInterestRatePercent: "",
    // Other Project expenses
    OtherProjectExpenses: "",

    // Deal expenses
    ProjectManagementRate: "",
    RateForInvestorCash: "",

    // capital stack 
    InvestorCash: "",
    BuilderCash: "",
    MinimumIRR: "",
    MaximumIRR: ""
}

export const JVSTSections = [
    //General
    [
        "ProjectName", "Description", "ProjectAddress",
    ],
    //Schedule:
    [
        "PreConstructionPeriod", "ConstructionPeriod", "SalePeriod",
    ],
    //Revenue: 
    [
        "HouseEstimatedSellPrice",
    ],
    //Costs:
    [
        "LandPurchase", "DesignPlanning", "SiteImprovements", "DirectConstruction", "ContingencyPercent",
        "CommissionsPercent", "TransferTaxesAndOtherDisposition", "CommercialFinanceCosts", "CommercialBorrowingInterestRatePercent",
        "OtherProjectExpenses", "ProjectManagementRate", "RateForInvestorCash",
    ],
    //Capital:
    [
        "InvestorCash", "BuilderCash", "MinimumIRR", "MaximumIRR",
    ]
]

export type JVSpecCalculations = {
    TotalDuration: string,
    TotalCash: string,

    Contingency: string,
    Commissions: string,

    CommercialFinanceCostsPercent: string,
    ConstructionInterest: string,
    LoanAmount: string,
    LoanToValuePercent: string,
    TotalFinancingCosts: string,
    TotalLandAndConstructionFunds: string,
    TotalCosts: string,

    GrossProfit: string,
    GrossMargin: string
    ProjectManagementFee: string,
    InterestOnInvestmentCashToInvestor: string,
    TotalDealExpenses: string,
    NetIncome: string,

    InvestorEquityPercent: string,
    BuilderEquityPercent: string,

    InvestorBaseReturnPercent: string
    BuilderBaseReturnPercent: string

    DistributionAmountOne: string
    InvestorDistributionAmountOne: string
    BuilderDistributionAmountOne: string
    BalanceAfterDistributionOne: string
    IRRAfterDistributionOne: string

    InvestorGrowthReturnPercent: string
    BuilderGrowthReturnPercent: string

    DistributionAmountTwo: string
    InvestorDistributionAmountTwo: string
    BuilderDistributionAmountTwo: string
    BalanceAfterDistributionTwo: string
    IRRAfterDistributionTwo: string

    InvestorPerformanceReturnPercent: string
    BuilderPerformanceReturnPercent: string

    DistributionAmountThree: string
    InvestorDistributionAmountThree: string
    BuilderDistributionAmountThree: string

    InvestorDistributionTotal?: string
    BuilderDistributionTotal?: string

    InvestorCashOut: string
    BuilderCashOut: string
    InvestorROE: string
    BuilderROE: string
    FinalIRR: string
}


export type JVSTUserDetails = {
    Logo: JSX.Element;
    CompanyName: string;
    PhoneNumber: string;
    EmailAddress: string;
    SignatoryName: string;
    SignatoryPosition: string;
    SignatoryTitle: string;
}

export interface InvestorTermsProps extends JVSpec, JVSpecCalculations {
    Logo: JSX.Element;
    CompanyName: string;
    PhoneNumber: string;
    EmailAddress: string;
    SignatoryName: string;
    SignatoryPosition: string;
}

export type PageNumberType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export type ProgressType = {
    section: number,
    page: PageNumberType
}
