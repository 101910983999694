import { JVSpec, JVSpecCalculations, JVSTUserDetails } from "./JVSpecTypes";


export function InvestorTermsBody(props: { form: JVSpec, calculations?: JVSpecCalculations }): JSX.Element {

    return (
        <main className="grid grid-cols-12 gap-y-2 py-3">
            {/* Purpose */}
            <span className="font-bold col-span-2">Purpose</span>
            <div className="grid grid-cols-12 col-span-10">
                <span className="col-span-10">&nbsp;</span>
                <span className="col-span-10">
                    To secure financing for a high-quality, luxury single-family spec home that meets market demand. The project aims to achieve a solid return on investment through careful planning, efficient construction, and thoughtful market positioning.
                </span>
            </div>

            {/* Project Summary */}
            <span className="font-bold col-span-12">Project Summary</span>
            <div className="col-span-2" />
            <div className="col-span-10">
                <div className="grid grid-cols-12">
                    <span className="col-span-4">Total Estimated Project Duration</span>
                    <span className="col-span-8">{props.calculations?.TotalDuration} {props.calculations?.TotalDuration ? props.calculations.TotalDuration === '1' ? 'month' : 'months' : ''}</span>

                    <span className="col-span-4">Total Project Cost</span>
                    <span className="col-span-8">{props.calculations?.TotalCosts}</span>

                    <span className="col-span-4">Estimated Sale Price</span>
                    <span className="col-span-8">{props.form.HouseEstimatedSellPrice}</span>

                    <span className="col-span-4">Estimated Gross Profit</span>
                    <span className="col-span-8">{props.calculations?.GrossProfit}</span>

                    <span className="col-span-4">Estimated Gross Margin</span>
                    <span className="col-span-8">{props.calculations?.GrossMargin}{props.calculations?.GrossMargin ? '%' : ''}</span>
                </div>
            </div>

            {/* Investor Summary */}
            <span className="font-bold col-span-12">Investor Summary</span>
            <div className="col-span-2" />
            <div className="col-span-10">
                <div className="grid grid-cols-12">
                    <span className="col-span-4">Interest on Investor Cash ({props.form.RateForInvestorCash || 0}{props.form.RateForInvestorCash ? '%' : ''})</span>
                    <span className="col-span-8">{props.calculations?.InterestOnInvestmentCashToInvestor}</span>

                    <span className="col-span-4">Distribution Project Profits</span>
                    <span className="col-span-8">{props.calculations?.InvestorDistributionTotal}</span>

                    <span className="col-span-4">Total Cash Out</span>
                    <span className="col-span-8">{props.calculations?.InvestorCashOut}</span>

                    <span className="col-span-4">ROE</span>
                    <span className="col-span-8">{props.calculations?.InvestorROE}{props.calculations?.InvestorROE ? '%' : ''}</span>

                    <span className="col-span-4">IRR</span>
                    <span className="col-span-8">{props.form.MaximumIRR}{props.form.MaximumIRR ? '%' : ''}</span>
                </div>
            </div>

            {/* Deal Expenses */}
            <span className="font-bold col-span-2">Deal Expenses</span>
            <div className="col-span-10 grid grid-cols-12">
                <span className="col-span-6">&nbsp;</span>
                <span className="col-span-3 font-bold">Investor</span>
                <span className="col-span-3 font-bold">Builder</span>

                <span className="col-span-6">Interest on Investor Cash {props.form.RateForInvestorCash ? `(${props.form.RateForInvestorCash}%)` : ''}</span>
                <span className="col-span-6">{props.calculations?.InterestOnInvestmentCashToInvestor}</span>

                <span className="col-span-9">Builder Project Management {props.form.ProjectManagementRate ? `(${props.form.ProjectManagementRate}%)` : ''}</span>
                <span className="col-span-3">{props.calculations?.ProjectManagementFee}</span>

                <span className="col-span-4">Total Deal Expenses</span>
                <span className="col-span-6">{props.calculations?.TotalDealExpenses}&nbsp;</span>
            </div>

            {/* Capital Stack */}
            <span className="font-bold col-span-12">Capital Stack</span>
            <div className="col-span-2" />
            <div className="col-span-10">
                <div className="grid grid-cols-12">
                    <span className="col-span-6">Cash</span>
                    <span className="col-span-3">{props.form.InvestorCash}</span>
                    <span className="col-span-3">{props.form.BuilderCash}</span>

                    <span className="col-span-6">Equity</span>
                    <span className="col-span-3">{props.calculations?.InvestorEquityPercent}{props.calculations?.InvestorEquityPercent ? '%' : ''}</span>
                    <span className="col-span-3">{props.calculations?.BuilderEquityPercent}{props.calculations?.BuilderEquityPercent ? '%' : ''}</span>

                    <span className="col-span-4">Total Cash</span>
                    <span className="col-span-8">{props.calculations?.TotalCash}</span>
                </div>
            </div>

            {/* Profit Allocation */}
            <span className="font-bold col-span-12">Profit Allocation</span>
            <div className="col-span-2" />
            <div className="col-span-10">
                <div className="grid grid-cols-12">
                    <span className="col-span-4">First</span>
                    <span className="col-span-2">{props.calculations?.DistributionAmountOne}</span>
                    <span className="col-span-3">{props.calculations?.InvestorBaseReturnPercent}{props.calculations?.InvestorBaseReturnPercent ? '%' : ''}</span>
                    <span className="col-span-3">{props.calculations?.BuilderBaseReturnPercent}{props.calculations?.BuilderBaseReturnPercent ? '%' : ''}</span>

                    <span className="col-span-4">Next</span>
                    <span className="col-span-2">{props.calculations?.DistributionAmountTwo}</span>
                    <span className="col-span-3">{props.calculations?.InvestorGrowthReturnPercent}{props.calculations?.InvestorGrowthReturnPercent ? '%' : ''}</span>
                    <span className="col-span-3">{props.calculations?.BuilderGrowthReturnPercent}{props.calculations?.BuilderGrowthReturnPercent ? '%' : ''}</span>

                    <span className="col-span-4">Next</span>
                    <span className="col-span-2">{props.calculations?.DistributionAmountThree}</span>
                    <span className="col-span-3">{props.calculations?.InvestorPerformanceReturnPercent}{props.calculations?.InvestorPerformanceReturnPercent ? '%' : ''}</span>
                    <span className="col-span-3">{props.calculations?.BuilderPerformanceReturnPercent}{props.calculations?.BuilderPerformanceReturnPercent ? '%' : ''}</span>
                </div>
            </div>
        </main>
    );
}


export function InvestorTerms(props: { form: JVSpec, calculations: JVSpecCalculations, userDetails: JVSTUserDetails }): JSX.Element {
    // TODO: GET USER DETAILS FROM JWT CALL


    return (
        <div id="InvestorTerms" className="text-pretty">
            <header className="grid grid-cols-12 border-b-2 py-2">
                <span className="col-span-3">{props.userDetails.Logo}</span>
                <span className="col-span-9 justify-self-end">{new Date().toLocaleDateString()}</span>

                <span className="col-span-12">{props.userDetails.CompanyName}</span>
                <span className="col-span-12">{props.userDetails.EmailAddress} | {props.userDetails.PhoneNumber}</span>

                <span className="col-span-12 text-center font-bold">Project Lending Opportunity</span>

                <span className="col-span-12">{props.form.ProjectName}</span>
                <span className="col-span-12">{props.form.ProjectAddress}</span>
            </header>

            <InvestorTermsBody form={props.form} calculations={props.calculations} />

            <section>
                {/* Signatory */}
                <div className="col-span-12 mt-4">{props.userDetails.SignatoryName}</div>
                <div className="col-span-12">{props.userDetails.SignatoryPosition}</div>
                <div className="col-span-12">{props.userDetails.CompanyName}</div>
                <div className="col-span-12">{props.userDetails.PhoneNumber}</div>
                <div className="col-span-12">{props.userDetails.EmailAddress}</div>
            </section>
        </div>
    );
}