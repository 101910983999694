import { useEffect, useMemo, useState } from "react";
import Button from "../../components/atoms/Button";
import Modal from "../../components/atoms/Modal";
import Report from "./Report";
import CheckMarkProgress from "./CheckMarkProgress";
import { GeneralPage1, GeneralPage2, SchedulePage, RevenuePage, CostingPage1, CostingPage2, CostingPage3, CapitalPage, CompletePage } from "./Pages";
import { JVSpecDefaultValues, PageNumberType, ProgressType, type JVSpec, JVSTSections, JVSpecCalculations } from "./JVSpecTypes"
import APIRequest from "../../helpers/CreateRequest";
import ReportModal from "./ReportModal";

const progessBarNextThreshholds = [2, 3, 4, 7, 8]
const progressBarPreviousThreshholds = [1, 2, 3, 6, 7]


export default function JVSpec() {
    const [progress, setProgress] = useState<ProgressType>({ section: 0, page: 0 });
    const [form, setForm] = useState<JVSpec>(JVSpecDefaultValues)
    const [triggerCalculations, setTriggerCalculations] = useState(false)
    const [isReportOpen, setIsReportOpen] = useState(false)
    const [isReportSelectOpen, setIsReportSelectOpen] = useState<'download' | 'email' | false>(false)
    const [isClearWarningOpen, setIsClearWarningOpen] = useState(false)
    const [calculations, setCalculations] = useState<JVSpecCalculations>()

    const triggerChange = () => setTriggerCalculations(!triggerCalculations)
    const componentsMap = useMemo(() => {
        return {
            0: <GeneralPage1 />,
            1: <GeneralPage2 form={form} setForm={setForm} />,
            2: <SchedulePage form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            3: <RevenuePage form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            4: <CostingPage1 form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            5: <CostingPage2 form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            6: <CostingPage3 form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            7: <CapitalPage form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            8: <CompletePage />
        }
    }, [form, setForm, progress]);

    const getCalculations = async () => {
        try {
            const result = await new APIRequest('/JVSpec/calculations', 'POST', null, form).GenerateRequest()

            if (result.status === 200) {
                const body = await result.json()
                if (body) {
                    setCalculations(body)
                } else throw new Error('Error fetching calculations')
            } else throw new Error('Error fetching calculations')
        } catch (err) {
            console.log(err)
        }
    }

    const handleNext = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page + 1) as PageNumberType;
            const newSection = progessBarNextThreshholds.includes(newPage) ? prev.section + 1 : prev.section;
            return { section: newSection, page: newPage };
        });
    }

    const handlePrevious = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page - 1) as PageNumberType;
            const newSection = progressBarPreviousThreshholds.includes(newPage) ? prev.section - 1 : prev.section;
            return { section: newSection, page: newPage };
        })
    }

    const handleBubbleClick = (section: number) => {
        setProgress({ section: section, page: section === 0 ? 0 : progessBarNextThreshholds[section - 1] as PageNumberType }
        )
    }

    useEffect(() => {
        getCalculations()
    }, [triggerCalculations])

    return (
        <div className="flex flex-col w-full h-full">
            <nav className="w-full h-[60px] bg-white-200 border-b-2 px-4 flex justify-between">
                <h1 className="flex text-base font-semibold items-center ">
                    Joint Venture Spec Tool
                </h1>

                <span className="flex m-auto mr-0 gap-x-3">
                    <Button label="Clear" action={() => setIsClearWarningOpen(true)} styleType="tonal" type="button" />
                    {
                        isClearWarningOpen && <Modal onClose={() => setIsClearWarningOpen(false)} className=" flex bg-white rounded-lg px-6 py-5 flex-col gap-y-5">
                            <h2 className="font-bold text-lg">Are you sure you want to clear the form?</h2>
                            <div className="flex gap-x-3 justify-between">
                                <Button label="No" action={() => setIsClearWarningOpen(false)} styleType="tonal" type="button" width="w-[80px]" />

                                <Button label="Yes" action={() => { setForm(JVSpecDefaultValues); setIsClearWarningOpen(false) }} styleType="tonal" type="button" width="w-[80px]" />
                            </div>
                        </Modal>
                    }
                    <span className="2xl:hidden">   <Button label="Reports" action={() => setIsReportOpen(true)} styleType="tonal" type="button" /> </span>
                    <Button label="Download" action={() => setIsReportSelectOpen('download')} styleType="tonal" type="button" />
                    <Button label="Email" action={() => setIsReportSelectOpen('email')} styleType="tonal" type="button" />
                </span>

            </nav>


            <div className="flex w-full h-[calc(100%-60px)]">
                <main className=" h-full flex-grow m-auto max-w-[1140px] overflow-auto py-5">
                    <header className="grid grid-cols-2 w-[580px] mx-auto flex-wrap">
                        <div className="w-full col-span-2 justify-center flex ">
                            <CheckMarkProgress key='CheckMarkProgress' progress={progress.section} steps={["General", "Schedule", "Revenue", "Costs", "Capital"]} handleClick={handleBubbleClick} form={form} formSectionsAndfields={JVSTSections} />
                        </div>
                    </header>

                    <form className="w-[580px] mx-auto" >
                        {componentsMap[progress.page] || <div>Error</div>}
                    </form>

                    <section className=" flex mx-auto w-[580px] pt-10 flex-row-reverse">
                        <span className="m-auto mr-0">
                            {progress.page < 8 && <Button label="Next" action={handleNext} styleType="tonal" type="button" width="w-[100px]" />}
                        </span>

                        <span className="m-auto ml-0">
                            {progress.page > 0 && <Button label="Previous" action={handlePrevious} styleType="tonal" type="button" width="w-[100px]" />}
                        </span>
                    </section>

                </main>


                <aside className=" hidden 2xl:flex flex-grow h-full w-full max-w-[700px] overflow-auto  bg-gray-100 width">
                    <Report form={form} setForm={setForm} calculations={calculations} getCalculations={getCalculations} />
                </aside>

                {
                    isReportOpen && (
                        <Modal
                            className="w-full max-w-[720px]  m-10 max-h-[90%] h-full bg-gray-100"
                            onClose={() => setIsReportOpen(false)}>
                                <Report form={form} setForm={setForm} calculations={calculations} getCalculations={getCalculations} closeIfModal={() => setIsReportOpen(false)}/>
                        </Modal>)
                }

                {calculations && isReportSelectOpen && <ReportModal isReportSelectOpen={isReportSelectOpen} setIsReportSelectOpen={setIsReportSelectOpen} form={form} calculations={calculations} />}

            </div>

        </div>
    )
}
