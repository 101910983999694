import { useState } from "react";

export interface BaseFieldProps {
    name: string;
    label: string;
    value?: string;
    setValue: React.Dispatch<React.SetStateAction<any>>
    defaultValue?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeydown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: string;
    required?: boolean;
    disabled?: boolean
    readonly?: boolean;
    size?: "md" | "lg";
    autoFocus?: boolean;
    aiAction?: () => Promise<string | false>
}

export default function BaseField(props: BaseFieldProps) {
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    const [aiInfo, setAiInfo] = useState<string>()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(e)
        const { name, value } = e.target
        props.setValue((formValues: any) => ({ ...formValues, [name]: value }))
    }

    const handleInvalid = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
        e.currentTarget.setCustomValidity(`Please enter a ${props.label.toLocaleLowerCase()}`)
    }

    const handleAiClick = async () => {
        setIsInfoVisible(true)
        if (props.aiAction) {
            const result = await props.aiAction()
            if(result){
                setAiInfo(result)
            }
        }
    }

    return (
        <div className={`${props.size === "md" ? "w-[250px]" : "w-[540px]"} min-h-[48px] max-h-min flex relative flex-col body-small`}>
            <div className="w-full relative flex justify-between">
                <label
                    htmlFor={props.name}
                    className={`w-fit h-[20px] max-h-max ${props.required ? "after:content-['*'] after:ml.5" : ""}}`
                    }
                >
                    {props.label}
                </label>
                {
                    isInfoVisible && <InfoSection setIsInfoVisible={setIsInfoVisible} aiInfo={aiInfo} setAiInfo={setAiInfo}/>
                }

                {
                    props.aiAction && <span className="flex cursor-pointer hover:animate-pulse hover:scale-125 transition-all duration-700" onClick={handleAiClick}>✨</span>
                }
            </div>

            <input
                name={props.name}
                type={props.type === 'date' ? 'date' : 'text'}
                id={props.name}
                value={props.value || ''}
                readOnly={props.readonly}
                disabled={props.disabled}
                onChange={handleChange}
                required={props.required}
                onInvalid={handleInvalid}
                onInput={(e) => e.currentTarget.setCustomValidity('')}
                onBlur={props.onBlur}
                onKeyDown={props.onKeydown}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                className={` w-full h-[28px] py-1 pl-2 ${props.type === 'percent' ? 'pr-[17px] text-right' : 'pr-2'} ${props.type === 'number' ? 'text-right' : ''} ${props.type === 'currency' ? 'text-right' : ''} border border-md-outline read-only:bg-gray-200`}
            />
            {props.type === 'percent' && <span className={`absolute right-[6px] top-[25px] `}>%</span>}
        </div>
    )
}

export function InfoSection({setIsInfoVisible, aiInfo, setAiInfo}: {setIsInfoVisible: React.Dispatch<React.SetStateAction<boolean>>, aiInfo?: string, setAiInfo: React.Dispatch<React.SetStateAction<string | undefined>>}) {
   
    const onClose = () => {
        setIsInfoVisible(false)
        setAiInfo(undefined)
    }
   
    return (
        <div className="px-2 py-1 absolute right-0 -top-7 z-20 bg-gray-300 rounded-xl flex gap-x-3 transition-all">
            <span className="transition-all min-h-[20px]">
                {
                    aiInfo ? aiInfo : <svg className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" viewBox="0 0 24 24" />
                }
            </span>
            <span className="cursor-pointer text-blue-500" onClick={onClose}>close</span>
        </div>
    )
}