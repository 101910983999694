import React, { useEffect, useState } from "react";

type status = 'complete' | 'partial' | 'empty'

export default function CheckMarkProgress(props: { progress: number, steps: string[], handleClick: (section: number) => void, form: any, formSectionsAndfields: string[][] }) {
    const width = `${props.steps.length * 122 + 5 - 30}px`; //steps * (circle width + line width) + padding - line width

    const [sectionStatus, setSectionStatus] = useState<status[]>([])

    // evaluaties and sets the state for the progress of the graphic
    useEffect(() => {
        let newSectionStatus: status[] = []

        props.formSectionsAndfields.forEach((section, index) => {
            let status: status = 'empty'
            let completeCount = 0
            section.forEach(field => {
                // check if the field is correct and increase count if so
                if (props.form[field] !== undefined && props.form[field] !== '' && props.form[field] !== '$') {
                    completeCount++
                }
            })

            // determine the status of the section
            if (completeCount === section.length) {
                status = 'complete'
            } else if (completeCount > 0) {
                status = 'partial'
            } else {
                status = 'empty'
            }

            // add the status to the array
            newSectionStatus.push(status)
        })

        setSectionStatus(newSectionStatus)
    }, [props.form])

    const checkLineColor = (index: number) => {
        // if all previous sections and the next section are empty or partial, return grey
        const slicedSectionStatus = sectionStatus.slice(0, index + 2)
        return slicedSectionStatus.includes('empty') || slicedSectionStatus.includes('partial') ? '#d3d3d3' : '#66bb6a'
    }

    return (
        <span className="flex flex-wrap">
            <svg width={width} height="100px" viewBox={`0 0 ${props.steps.length * 122 + 5 - 80} 100`} xmlns="http://www.w3.org/2000/svg">
                {

                    // Generate lines first
                    props.steps.map((step, index) => (
                        props.steps.length > index + 1
                        &&
                        <line
                            x1={40 + index * 122 + 5}
                            y1="30"
                            x2={42 + index * 122 + 80 + 5}
                            y2="30"
                            stroke={checkLineColor(index)}// THIS IS BREAKING EVERYTHING
                            strokeWidth="2"
                            key={`line-${index}`}
                        />
                    ))
                }
                {
                    // Generate circles and text
                    props.steps.map((step, index) => {
                        const status = sectionStatus[index] || 'empty'
                        return (
                            <React.Fragment key={`circle-text-${index}`}>
                                <circle
                                    cx={index * 122 + 20 + 5}
                                    cy="30"
                                    r="20"
                                    fill={status === 'complete' ? '#66bb6a' : '#d3d3d3'}
                                    stroke={status !== 'empty' ? '#66bb6a' : '#d3d3d3'}
                                    strokeWidth="2" fillOpacity={status === 'complete' ? 1 : 0}
                                    key={`circle-${index}`}
                                    onClick={() => props.handleClick(index)}
                                    className={`cursor-pointer hover:animate-pulse`}

                                />
                                {status === 'complete' && <path
                                    d={`M${12 + index * 122 + 5},30 L${18 + index * 122 + 5},36 L${30 + index * 122 + 5},24`}
                                    stroke="white"
                                    strokeWidth="2"
                                    fill="none"
                                    key={`check-${index}`}
                                />}
                                <text
                                    x={index * 122 + 20 + 5}
                                    y="70"
                                    textAnchor="middle"
                                    fontSize="15"
                                    fill="#000"
                                    key={`text-${index}`}
                                    onClick={() => props.handleClick(index)}
                                    className={`cursor-pointer hover:animate-pulse ${index === props.progress ? 'font-semibold' : ''}`}
                                >

                                    {step}
                                </text>
                            </React.Fragment>
                        )
                    })
                }
            </svg>
        </span>
    )
}