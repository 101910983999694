import { useEffect, useState } from "react"
import { UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form"
import NumberField from "../../components/atoms/forms/Fields/NumberField"
import { GetMonthIndexByNameSubstring } from "./GetMonth"

export type UnitsRemainingType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    watch: UseFormWatch<any>
}

export default function UnitsRemaining(props: UnitsRemainingType) {
    const [supportingText, setSupportingText] = useState(props.supportingText)

    const estimatedUnitsSoldFieldNames = ['YearOneJanuaryUnitCount', 'YearOneFebruaryUnitCount', 'YearOneMarchUnitCount', 'YearOneAprilUnitCount', 'YearOneMayUnitCount', 'YearOneJuneUnitCount', 'YearOneJulyUnitCount', 'YearOneAugustUnitCount', 'YearOneSeptemberUnitCount', 'YearOneOctoberUnitCount', 'YearOneNovemberUnitCount', 'YearOneDecemberUnitCount', 'YearTwoJanuaryUnitCount', 'YearTwoFebruaryUnitCount', 'YearTwoMarchUnitCount', 'YearTwoAprilUnitCount', 'YearTwoMayUnitCount', 'YearTwoJuneUnitCount', 'YearTwoJulyUnitCount', 'YearTwoAugustUnitCount', 'YearTwoSeptemberUnitCount', 'YearTwoOctoberUnitCount', 'YearTwoNovemberUnitCount', 'YearTwoDecemberUnitCount']
    const yearsFieldNames = ['YearOne', 'YearTwo']

    const watchedEstimatedUnitsSoldValues = estimatedUnitsSoldFieldNames.map(field => props.watch(field));
    const watchedYearValues = yearsFieldNames.map(field => props.watch(field));
    const watchedCurrentMonthActualSalesUnitCount = props.watch('CurrentMonthActualSalesUnitCount') || 0;
    
    const watchOriginalUnitsRemaining = props.watch('UnitsRemainingOriginal')

    useEffect(()=> {
        if(!props.disabled){
            const currentYear = new Date().getFullYear()
            const currentMonth = new Date().getMonth()
            let unitsRemaining = Number(watchOriginalUnitsRemaining) || 0

            estimatedUnitsSoldFieldNames.forEach((fieldName, index) => {
                const fieldMonthNumber = GetMonthIndexByNameSubstring(fieldName)
                const fieldYearNumber = Number(fieldName.includes('YearOne') ? watchedYearValues[0] : watchedYearValues[1])

                if( 
                    (fieldYearNumber === currentYear && (fieldMonthNumber) >= currentMonth) ||
                    fieldYearNumber > currentYear
                ){
                    if(fieldMonthNumber === currentMonth && fieldYearNumber === currentYear && watchedCurrentMonthActualSalesUnitCount[0]){
                        const fieldValue = Number(watchedEstimatedUnitsSoldValues[index]) || 0
                        unitsRemaining = unitsRemaining - fieldValue + Number(watchedCurrentMonthActualSalesUnitCount)
                    } else {
                        const fieldValue = Number(watchedEstimatedUnitsSoldValues[index]) || 0
                        unitsRemaining = unitsRemaining - fieldValue
                    }

                }
            })
            props.setValue("UnitsRemaining", unitsRemaining)
        }
    }, [watchedEstimatedUnitsSoldValues, watchOriginalUnitsRemaining])

    return (
        <NumberField
            {...props}
            readonly={true}
            supportingText={supportingText}
        />
    )
}
