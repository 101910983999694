import { useEffect, useState } from "react";
import Button from "../../components/atoms/Button";
import APIRequest from "../../helpers/CreateRequest";

type isRefreshingType = { isRefreshing: boolean, syncOnprem: boolean, refreshAllSalesPlans: boolean }

export default function DataManagement() {
    const [lastRefresh, setLastRefresh] = useState('')
    const [progress, setProgress] = useState(5)
    const [timerID, setTimerID] = useState<NodeJS.Timeout>()
    const [isRefreshing, setIsRefreshing] = useState<isRefreshingType>({ isRefreshing: false, syncOnprem: false, refreshAllSalesPlans: false })

    //get last refresh
    const getLastRefresh = async () => {
        try {
            const result = await new APIRequest('/lastRefresh', 'GET', null, null).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    setLastRefresh(setNewDateString(body.recordset[0].LastRefreshTimestamp))
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const refreshingComplete = (success: boolean) => {
        clearTimeout(timerID)
        if (success) setProgress(100)
        setTimeout(() => {
            setIsRefreshing({
                isRefreshing: false,
                syncOnprem: false,
                refreshAllSalesPlans: false
            })
            setProgress(5)
            getLastRefresh()
        }, 3000)
    }


    const syncOnprem = async () => {
        setProgress(progress * 1.01)
        setIsRefreshing({ ...isRefreshing, isRefreshing: true, syncOnprem: true })
        try {
            const result = await new APIRequest('/sync-on-prem', 'GET', null, null).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    refreshingComplete(true)
                } else throw result.statusText
            } else throw result.statusText


        } catch (err) {
            console.log(err)
            refreshingComplete(false)
        }
    }


    useEffect(() => {
        const newPercent = progress + Math.random() * 5
        if (isRefreshing.isRefreshing && progress < 100 && !timerID && newPercent < 100) {
            const tempTimerID = setTimeout(() => {
                if (isRefreshing.isRefreshing && progress < 100) { setProgress(newPercent); setTimerID(undefined) }
            }, 500)
            setTimerID(tempTimerID)
        } else {
            clearTimeout(timerID)
            setTimerID(undefined)
        }
    }, [progress])


    useEffect(() => { getLastRefresh() }, [])



    return (
        <div className="py-10 px-20 h-full overflow-y-auto flex flex-col w-full">
            
            {/* Menu Section Title */}
            <div className="font-bold text-xl mb-[10px]">
                <span>Data Management</span>
                
            </div>

            <div className="mb-4">
                Last Refresh: {lastRefresh}
            </div>

            <div className="grid grid-cols-[max-content_auto] items-center gap-4 max-w-[600px]">

                <Button
                    label="Sync On-prem"
                    styleType="tonal"
                    type="button"
                    key="SyncOnpremBtn"
                    action={syncOnprem}
                    disabled={isRefreshing.isRefreshing}
                />

                {
                    isRefreshing.syncOnprem ? <ProgressBar progress={progress} isRefreshing={isRefreshing.syncOnprem} /> : <span></span>
                }

            </div>
        </div>
    )
}

const ProgressBar = ({ progress, isRefreshing }: { progress: number, isRefreshing: boolean }) => {

    return (
        <div className={` bg-gray-200 rounded-full h-4 transition-all duration-300 `} style={{ opacity: isRefreshing ? '100%' : '0%' }}>
            <div
                className={` ${progress === 100 ? 'bg-emerald-300' : 'bg-md-primary'} h-4 rounded-full transition-all duration-300`}
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};

const setNewDateString = (value: string | undefined) => {
    if (value?.endsWith('M')) {
        return value
    }
    else if (value) {
        const fullDate = new Date(value).toISOString()
        if (fullDate) {
            const date = new Date(fullDate).toLocaleDateString().split('T')[0]

            const time = fullDate.split('T')[1].split('.')[0].split(':')
            const hour = Number(time[0])
            const minutes = time[1]
            const seconds = time[2]
            const convertedHour = hour - 12 <= 0 ? hour === 0 ? 12 : hour : hour - 12

            const meridiem = hour < 12 ? 'AM' : 'PM'

            return (`${date} ${convertedHour}:${minutes}:${seconds} ${meridiem}`)
        }
    }

    return ''
}