
import { useState } from "react";
import {InfoSection} from "./BaseField";

interface TextareaProps {
    name: string;
    label: string;
    value?: string;
    setValue: React.Dispatch<React.SetStateAction<any>>
    defaultValue?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onKeydown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;

    placeholder?: string;
    type?: string;
    required?: boolean;
    disabled?: boolean
    readonly?: boolean;
    size?: "md" | "lg";
    autoFocus?: boolean;
    aiAction?: () => Promise<string | false>
    boldLabel?: boolean;
}

export default function TextareaField(props: TextareaProps) {
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    const [aiInfo, setAiInfo] = useState<string>()

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange) props.onChange(e)
        const { name, value } = e.target
        props.setValue((formValues: any) => ({ ...formValues, [name]: value }))
    }

    const handleInvalid = (e: React.FormEvent<HTMLTextAreaElement>) => {
        e.currentTarget.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
        e.currentTarget.setCustomValidity(`Please enter a ${props.label.toLocaleLowerCase()}`)
    }

    const handleAiClick = async () => {
        setIsInfoVisible(true)
        if (props.aiAction) {
            const result = await props.aiAction()
            if (result) {
                setAiInfo(result)
            }
        }
    }





    return (
        <div className={`${props.size === "md" ? "w-[250px]" : "w-[540px]"} min-h-[48px] max-h-min flex relative flex-col body-small`}>

            <div className="w-full relative flex justify-between">
                <label
                    htmlFor={props.name}
                    className={`w-fit h-[20px] max-h-max ${props.required ? "after:content-['*'] after:ml.5" : ""} ${props.boldLabel ? "font-semibold" : ""}`
                    }
                >
                    {props.label}
                </label>
                {
                    isInfoVisible && <InfoSection setIsInfoVisible={setIsInfoVisible} aiInfo={aiInfo} setAiInfo={setAiInfo} />
                }

                {
                    props.aiAction && <span className="flex cursor-pointer hover:animate-pulse hover:scale-125 transition-all duration-700" onClick={handleAiClick}>✨</span>
                }
            </div>
            <textarea
                name={props.name}
                id={props.name}
                value={props.value || ''}
                readOnly={props.readonly}
                disabled={props.disabled}
                onChange={handleChange}
                required={props.required}
                onInvalid={handleInvalid}
                onInput={(e) => e.currentTarget.setCustomValidity('')}
                onBlur={props.onBlur}
                onKeyDown={props.onKeydown}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                className={` w-full h-[28px] py-1 pl-2 pr-2 border border-md-outline read-only:bg-gray-200`}
            />
        </div>

    );
}
