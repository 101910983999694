import { useState } from "react"


type ButtonType = {
    label: string | JSX.Element,
    styleType: 'elevated' | 'filled' | 'tonal' | 'outlined' | 'text' | 'selection'
    type: 'button' | 'submit'
    action: Function
    actionParams?: any
    selected?: any
    disabled?: boolean
    width?: string
}

export default function Button({ label, styleType, type, action, actionParams, selected, disabled, width }: ButtonType) {

    const [isPerformingAction, setIsPerformingAction] = useState(false)

    const actionHandler = async () => {
        setIsPerformingAction(true)
        await action(actionParams)
        setIsPerformingAction(false)
    }

    return (

        styleType === 'elevated' ?

            <></>

            :

            styleType === 'filled' ?

                <button
                    key={`button${label}${styleType}`}
                    type={type}
                    disabled={disabled}
                    onClick={actionHandler}
                    className={
                        `rounded-full bg-md-primary px-[24px] h-[40px] text-center  outline-none   
                         shadow-black label-large
                        ${!disabled ? 'hover:shadow-md hover:opacity-80 text-md-on-primary ' : 'bg-md-on-surface opacity-[.38] text-md-on-surface'}
                         focus:bg-md-secondary 
                         `}
                >
                    <>{isPerformingAction ?
                            <div className="p-2 h-[40px] w-[40px]">
                                <svg className="animate-spin inline-block m-auto w-full h-full border-[3px] border-current border-t-transparent text-md-inverse-primary rounded-full" viewBox="0 0 16 16" />
                            </div>

                            :label}</>
                </button>

                :

                styleType === 'tonal' ?

                    <button 
                        key={`button${label}${styleType}`}
                        type={type}
                        disabled={disabled || isPerformingAction}
                        onClick={actionHandler}

                        className={
                            `rounded-full text-gray-800 bg-sky-200 shadow-black px-[24px] h-[40px] text-center label-large outline-none transition-shadow  
                             disabled:bg-gray-200 disabled:text-opacity-70
                            ${!disabled ? 'hover:shadow-md hover:text-gray-900' : ''}
                            focus:bg-sky-300 focus:text-gray-900
                            ${width ? width : ''}
                            `}
                    >
                        <>{isPerformingAction ?
                            <div className="p-2 h-[40px] w-[40px]">
                                <svg className="animate-spin inline-block m-auto w-full h-full border-[3px] border-current border-t-transparent text-md-primary rounded-full" viewBox="0 0 16 16" />
                            </div>

                            :

                            label}</>
                    </button>

                    :

                    styleType === 'outlined' ?

                        <button
                            key={`button${label}${styleType}`}
                            type={type}
                            disabled={disabled}
                            onClick={actionHandler}

                            className={
                                `rounded-full text-md-primary bg-transparent px-[24px] h-[40px] text-center label-large border border-md-outline  outline-none  transition-shadow  
                                ${disabled ? 'opacity-[.12] text-md-on-surface' : 'hover:text-md-primary hover:border-md-outline'} 
                                 focus:border-md-primary focus:text-md-primary`
                            }
                        >
                            <>{isPerformingAction ?
                                <div className="p-2 h-[40px] w-[40px]">
                                    <svg className="animate-spin inline-block m-auto w-full h-full border-[3px] border-current border-t-transparent text-md-primary rounded-full" viewBox="0 0 16 16" />
                                </div>

                                : label}</>
                        </button>

                        :

                        styleType === 'text' ?

                            <button
                                key={`button${label}${styleType}`}
                                type={type}
                                onClick={actionHandler}
                                disabled={disabled}
                                className={`rounded-full h-[40px] px-[12px] text-nowrap shadow-black label-large ${disabled ? 'text-md-on-surface opacity-[.38]' : 'text-md-primary focus:text-md-secondary  '} `}
                            >

                                {isPerformingAction ?
                                    <div className="p-2 h-[40px] w-[40px] ">
                                        <svg className="animate-spin inline-block m-auto w-full h-full border-[3px] border-current border-t-transparent text-md-primary rounded-full" viewBox="0 0 16 16" />
                                    </div>

                                    : label}
                            </button>

                            :

                            styleType === 'selection' ?

                            <button
                            key={`button${label}${styleType}`}
                            type={type}
                            disabled={disabled}
                            onClick={actionHandler}
                            className={
                                `rounded-full bg-md-primary px-[24px] h-[40px] text-center  outline-none   
                                 shadow-black label-large
                                ${!disabled ? 'hover:shadow-md hover:opacity-80 text-md-on-primary ' : 'bg-md-on-surface opacity-[.38] text-md-on-surface'} ${selected === label ? 'bg-md-secondary' : 'bg-md-primary'}
                                 `} 
                        >
                            <>{isPerformingAction ?
                                    <div className="p-2 h-[40px] w-[40px]">
                                        <svg className="animate-spin inline-block m-auto w-full h-full border-[3px] border-current border-t-transparent text-md-inverse-primary rounded-full" viewBox="0 0 16 16" />
                                    </div>
        
                                    :label}</>
                        </button>
        
                            :

                            <></>

    )
}