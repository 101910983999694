import jsPDF from "jspdf";
import { JVSTUserDetails, type JVSpec, type JVSpecCalculations } from "./JVSpecTypes";
import { useEffect, useState } from "react";
import LoanTerms from "./LoanTerms";
import Modal from "../../components/atoms/Modal";
import BEPLogo from "../../assets/BEP-Logo.png";
import { Cookies } from "react-cookie";
import APIRequest from "../../helpers/CreateRequest";
import SnackBar from "../../components/molecules/SnackBar";
import { InvestorTerms } from "./InvestorTerms";
import { ProjectSummary } from "./ProjectSummary";


const reports = [
    {
        name: "Summary",
        id: 'ProjectSummary',
        svg: <svg xmlns="http://www.w3.org/2000/svg" height="64px" viewBox="0 -960 960 960" width="64px" fill="#000000"><path d="M320-600q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm0 160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0 160q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h440l200 200v440q0 33-23.5 56.5T760-120H200Zm0-80h560v-400H600v-160H200v560Zm0-560v160-160 560-560Z"/></svg>
    },
    {
        name: "Investor",
        id: 'InvestorTerms',
        svg: <svg xmlns="http://www.w3.org/2000/svg" height="64px" viewBox="0 -960 960 960" width="64px" fill="#000000"><path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z" /></svg>
    },
    {
        name: "Loan",
        id: 'LoanTerms',
        svg: <svg xmlns="http://www.w3.org/2000/svg" height="64px" viewBox="0 -960 960 960" width="64px" fill="#000000"><path d="M160-391h45l23-66h104l24 66h44l-97-258h-46l-97 258Zm81-103 38-107h2l38 107h-78Zm319-70v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-600q-38 0-73 9.5T560-564Zm0 220v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-380q-38 0-73 9t-67 27Zm0-110v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-490q-38 0-73 9.5T560-454ZM260-320q47 0 91.5 10.5T440-278v-394q-41-24-87-36t-93-12q-36 0-71.5 7T120-692v396q35-12 69.5-18t70.5-6Zm260 42q44-21 88.5-31.5T700-320q36 0 70.5 6t69.5 18v-396q-33-14-68.5-21t-71.5-7q-47 0-93 12t-87 36v394Zm-40 118q-48-38-104-59t-116-21q-42 0-82.5 11T100-198q-21 11-40.5-1T40-234v-482q0-11 5.5-21T62-752q46-24 96-36t102-12q58 0 113.5 15T480-740q51-30 106.5-45T700-800q52 0 102 12t96 36q11 5 16.5 15t5.5 21v482q0 23-19.5 35t-40.5 1q-37-20-77.5-31T700-240q-60 0-116 21t-104 59ZM280-499Z" /></svg>
    }

]

export default function ReportModal({ isReportSelectOpen, setIsReportSelectOpen, form, calculations, isDownload }: { isReportSelectOpen: 'download' | 'email' | false, setIsReportSelectOpen: React.Dispatch<React.SetStateAction<false | "download" | "email">>, form: JVSpec, calculations: JVSpecCalculations, isDownload?: boolean }) {
    const [downloadedIDs, setDownloadedIDs] = useState<string[]>([])
    const [snackBar, setSnackBar] = useState<{ message: string, refresh: boolean }>({ message: '', refresh: false })

    const [userDetails, setUserDetails] = useState<JVSTUserDetails>({
        Logo: <img src={BEPLogo} alt="BEP Logo" width="100px" height="100px" />,
        CompanyName: new Cookies().get('settings').TenantName || "[Company Name]",
        PhoneNumber: "[Phone Number]",
        EmailAddress: "",
        SignatoryName: "[User Name]",
        SignatoryPosition: "[User Position]",
        SignatoryTitle: "[User Position]"
    })


    const downloadFile = async (report: string) => {
        try {
            // Get the content to download
            const downloadContent = document.getElementById(report) as HTMLElement;

            if (!downloadContent) return;

            // Create and save the PDF once content is fully rendered
            const doc = new jsPDF();
            await doc.html(downloadContent, {
                callback: (doc) => {
                    doc.save(`${form.ProjectName}_${report}.pdf`, { returnPromise: true });
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                filename: `${form.ProjectName}_${report}.pdf`,
                x: 0,
                y: 0,
                width: 180,
                windowWidth: 900,
            });

            setSnackBar({ message: 'Downloaded successfully', refresh: !snackBar.refresh })
        } catch (err) {
            setSnackBar({ message: 'Downloaded unsuccessfully', refresh: !snackBar.refresh })
        } finally {
            setDownloadedIDs([...downloadedIDs, report]);
        }
    }

    const emailFile = async (report: string) => {
        try {
            // Get the content to download
            const downloadContent = document.getElementById(report) as HTMLElement;
            if (!downloadContent) return;

            // Create and save the PDF once content is fully rendered
            const doc = new jsPDF();
            const fileName = `${form.ProjectName}_${report}.pdf`

            if (!userDetails.EmailAddress) throw new Error('Email not found')

            await doc.html(downloadContent, {
                callback: async (doc) => {
                    const pdfBlob = doc.output('blob')

                    const formData = new FormData();
                    formData.append('file', pdfBlob, fileName);
                    formData.append('recipient', userDetails.EmailAddress)
                    formData.append('fileName', fileName)

                    // email download content

                    const result = await new APIRequest("/projects/jvst/email-report", "POST", null, formData, 'file').GenerateRequest()

                    if (result.status !== 200) {
                        setSnackBar({ message: result.statusText, refresh: !snackBar.refresh })
                    } else if (result.status === 200) {
                        const body = await result.json()
                        if (body.status !== 200) {
                            setSnackBar({ message: body.message, refresh: !snackBar.refresh })
                        } else {
                            setSnackBar({ message: body.message, refresh: !snackBar.refresh })
                        }
                    }
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                filename: fileName,
                x: 0,
                y: 0,
                width: 180,
                windowWidth: 900
            })


        } catch (err) {
            setSnackBar({ message: String(err), refresh: !snackBar.refresh })
        } finally {
            setDownloadedIDs([...downloadedIDs, report]);
        }
    }

    useEffect(() => {
        const getUserDataToken = async () => {
            try {
                const result = await new APIRequest("/auth/decode-user-data-token", "POST", null, { userDataToken: new Cookies().get("userDataToken") }).GenerateRequest();
                if (result.status === 200) {
                    const userData = (await result.json()).userData
                    setUserDetails({
                        Logo: <img src={BEPLogo} alt="BEP Logo" width="100px" height="100px" />,
                        CompanyName: new Cookies().get('settings').TenantName || "[Company Name]",
                        PhoneNumber: userData.PhoneNumber || "[Phone Number]",
                        EmailAddress: userData.Email || "[Email Address]",
                        SignatoryName: (userData.FirstName && userData.LastName) ? (userData.FirstName + " " + userData.LastName) : "[User Name]",
                        SignatoryPosition: userData.Position || "[User Position]",
                        SignatoryTitle: userData.Title || "[User Title]"
                    })
                }
            } catch (err) {
                console.log(err)
            }
        }
        getUserDataToken()
    }, [])
       

    return (
        <>
            {
                isReportSelectOpen && <Modal className="w-min max-w-[9000px] p-10 m-20 max-h-[700px] h-min bg-gray-100 overflow-auto rounded-md " onClose={() => setIsReportSelectOpen(false)}>
                    <div className="flex flex-wrap gap-x-5 gap-y-10 justify-between">
                        <div className="w-max flex flex-nowrap gap-x-4 justify-between text-center text-2xl font-semibold">

                            <span>Select reports to {isReportSelectOpen}</span>

                            <svg onMouseDown={() => setIsReportSelectOpen(false)} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#000000"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>

                        </div>
                        {
                            reports.map((report, index) => (
                                <DownloadSquare
                                    key={index}
                                    name={report.name}
                                    handleMouseDown={isReportSelectOpen === 'download' ? () => downloadFile(report.id) : isReportSelectOpen === 'email' ? () => emailFile(report.id) : () => null}
                                    svg={report.svg}
                                    isDownloaded={downloadedIDs.find(id => id === report.id) ? true : false}
                                />

                            ))
                        }
                    </div>
                </Modal>
            }

            <div className="hidden">

                <ProjectSummary form={form} calculations={calculations} userDetails={userDetails}  />

                <LoanTerms form={form} calculations={calculations} userDetails={userDetails} />

                <InvestorTerms form={form} calculations={calculations} userDetails={userDetails} />
            </div>


            <SnackBar message={snackBar} />
        </>
    )
}


const DownloadSquare = ({ svg, name, handleMouseDown, isDownloaded }: { svg: React.ReactNode, name: string, handleMouseDown: Function, isDownloaded: boolean }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleMouseDownLocal = async () => {
        if (isLoading) return;
        setIsLoading(true);
        handleMouseDown();
    };

    useEffect(() => {
        if (isDownloaded) setIsLoading(false)
    }, [isDownloaded])

    return (
        <div
            className={`relative flex w-[136px] h-[136px] flex-col items-center justify-center px-4 py-4 rounded-sm gap-x-3 border-2 transition-colors duration-300  cursor-pointer 
                ${isDownloaded
                    ? 'border-green-600 bg-gradient-to-br  from-green-300/50 to-green-100/50'
                    : 'border-sky-50 hover:border-sky-400 bg-gradient-to-br from-sky-100 to-sky-200'
                }`}
            onMouseDown={handleMouseDownLocal}
        >
            {/* Main content */}
            <div>{svg}</div>
            <div className="font-semibold text-xl">{name}</div>

            {
                isLoading && (
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-slate-100/80">
                        <div className="animate-spin rounded-full h-10 w-10 border-2 border-b-transparent border-sky-500"></div>
                    </div>
                )
            }
        </div>
    );
};

