import { type JVSpec } from "./JVSpecTypes";
import TextField from "../../components/atoms/forms/SimpleFields/TextField";
import NumberField from "../../components/atoms/forms/SimpleFields/NumberField";
import CurrencyField from "../../components/atoms/forms/SimpleFields/CurrencyField";
import PercentField from "../../components/atoms/forms/SimpleFields/PercentField";
import RequestAI from "./RequestAI";
import TextareaField from "../../components/atoms/forms/SimpleFields/TextareaField";


function InputDescription({ text, additionalClasses }: { text: string, additionalClasses?: string }) {
    return <p className={`body-small  ${additionalClasses ? additionalClasses : ' '}`}>{text}</p>
}

export function GeneralPage1() {

    return (
        <div className="flex flex-grow flex-wrap text-wrap overflow-auto  w-[580px] min-w-[580px] body-small">

            <div className="text-wrap">
                <p>
                    The Joint Venture Spec Tool simplifies builder-investor collaboration for the financing and construction of a spec home by providing a comprehensive view of crucial financial data and anticipated outcomes. It equips home builders to attract and engage investors by collecting key inputs such as sales price, construction costs, project schedule, capital contributions, and profit distribution. By promoting transparency, the tool helps both parties gain a clear understanding of capital requirements and potential returns.
                </p>
                <br />
                <p>
                    To ensure a smooth experience, please gather the following information before you begin:
                </p>

                <ul className="list-disc p-5 grid grid-cols-2 gap-x-4 text-nowrap">
                    <li>Deal Name</li>
                    <li>Description</li>
                    <li>Months Held Until Settlement</li>
                    <li>Months Construction</li>
                    <li>Months Hold Until Construction</li>
                    <li>House Estimated Sell Price</li>
                    <li>Land Purchase</li>
                    <li>Design Planning</li>
                    <li>Site Improvements</li>
                    <li>Direct Construction</li>
                    <li>Commissions %</li>
                    <li>Transfer Taxes and Other Disposition</li>
                    <li>Other Project Expenses</li>
                    <li>Project Management Rate</li>
                    <li>Rate for Investor Cash</li>
                    <li>Investor Cash</li>
                    <li>Builder Cash</li>
                    <li>Minimum IRR</li>
                    <li>Maximum IRR</li>
                    <li >Commercial Finance Costs</li>
                    <li className="col-span-2">Commercial Borrowing Interest Rate %</li>
                </ul>

            </div>
        </div>
    )
}

export function GeneralPage2({ form, setForm }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">General</h5>
            <TextField name="ProjectName" label="Project Name" size="md" value={form.ProjectName} setValue={setForm} autoFocus />
            <InputDescription text="Enter a unique and descriptive name for the joint venture project. This name should help you and the investor easily identify and reference the project in all communications and documents." />
            <TextareaField name="Description" label="Description" size="md" value={form.Description} setValue={setForm} type="textarea" />
            <InputDescription text="Provide a brief overview of the project, including details such as the type of spec home, its location, key features, and overall objectives. This description will help both you and the investor understand the scope and vision of the venture." />
            <TextField name="ProjectAddress" label="Project Address" size="md" value={form.ProjectAddress} setValue={setForm} />
            <InputDescription text="Enter the address of the property where the spec home will be built. This location will serve as the site for the joint venture project and should be specified accurately for planning and reference purposes." />
        </div>
    );
}

export function SchedulePage({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Project Schedule</h5>
            <NumberField name="PreConstructionPeriod" label="Pre-Construction Period" size="md" value={form.PreConstructionPeriod} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Enter the number of months you anticipate holding the property before beginning construction. This period accounts for any delays or preparation time needed before starting the build." />
            <NumberField name="ConstructionPeriod" label="Construction Period" size="md" value={form.ConstructionPeriod} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Input the estimated duration of the construction phase in months. This should include all time required to complete the building from start to finish." />
            <NumberField name="SalePeriod" label="Sale Period" size="md" value={form.SalePeriod} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Specify the number of months you expect to hold the property after construction is completed but before it is sold. This period may include time for finalizing details or waiting for the right market conditions." />
        </div>
    );
}

export function RevenuePage({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Revenue</h5>
            <CurrencyField name="HouseEstimatedSellPrice" label="House Estimated Sell Price" size="md" value={form.HouseEstimatedSellPrice} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Input the projected selling price of the home once it's completed. This estimate should reflect current market conditions and expected value based on similar properties in the area." />
        </div>
    );
}

export function CostingPage1({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Acquisition</h5>
            <CurrencyField name="LandPurchase" label="Land Purchase" size="md" value={form.LandPurchase} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Enter the estimated cost of acquiring the land where the spec home will be built. This includes the price of the land itself and any associated purchase fees." />

            <h5 className="col-span-2 border-b-2 font-semibold">Planning and Permitting</h5>
            <CurrencyField name="DesignPlanning" label="Design / Planning" size="md" value={form.DesignPlanning} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="..." />

            <h5 className="col-span-2 border-b-2 font-semibold">Site Improvements</h5>
            <CurrencyField name="SiteImprovements" label="Site Improvements" size="md" value={form.SiteImprovements} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Provide the estimated costs for any improvements required on the land before construction can begin. This might include grading, utility hookups, or other preparatory work." />

            <h5 className="col-span-2 border-b-2 font-semibold">Direct Construction Costs</h5>
            <CurrencyField name="DirectConstruction" label="Direct Construction" size="md" value={form.DirectConstruction} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the estimated costs directly related to the construction of the home, including materials and labor. This should cover all aspects of building the house." />

            <h5 className="col-span-2 border-b-2 font-semibold">Contingency</h5>
            <PercentField name="ContingencyPercent" label="Contingency %" size="md" value={form.ContingencyPercent} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the percentage of the total project cost that will be set aside as a contingency fund. This amount is used to cover unexpected expenses or changes during the construction process." />

        </div>
    );
}

export function CostingPage2({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    const systemPromt = "You are an expert in modern home building investments. You have vast and insightful knowledge about how joint venture deals between investors and home builders work. The user will ask what a good commercial borrowing interest rate is based on the current market conditions. Provide a recommendation based on the current market conditions. Keep this response very short."
    const userPrompt = `What is a good commercial borrowing interest rate for a joint venture deal between an investor and a home builder right now?`

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Sales and Marketing</h5>
            <PercentField name="CommissionsPercent" label="Commissions %" size="md" value={form.CommissionsPercent} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Enter the percentage of the sale price that will be allocated to commissions for real estate agents and brokers. This rate covers the cost of marketing and selling the property." />

            <h5 className="col-span-2 border-b-2 font-semibold">Disposition</h5>
            <CurrencyField name="TransferTaxesAndOtherDisposition" label="Transfer Taxes and Other Disposition" size="md" value={form.TransferTaxesAndOtherDisposition} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Input the estimated costs associated with transferring the property to the buyer and other disposition expenses. This amount should cover all fees and taxes related to the sale of the home." />

            <h5 className="col-span-2 border-b-2 mb-1 font-semibold">Financing</h5>
            <CurrencyField name="CommercialFinanceCosts" label="Commercial Finance Costs" size="md" value={form.CommercialFinanceCosts} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Input the total costs associated with financing the project, including loan origination fees, points, and other expenses. This amount reflects the cost of securing financing for the venture." />
            <PercentField name="CommercialBorrowingInterestRatePercent" label="Commercial Borrowing Interest Rate %" size="md" value={form.CommercialBorrowingInterestRatePercent} setValue={setForm} aiAction={async () => RequestAI(systemPromt, userPrompt)} onBlur={triggerCalculations} />
            <InputDescription text="Input the interest rate for any commercial loans or lines of credit used to finance the project. This rate affects the cost of borrowing and the overall financial performance of the venture." />
        </div>
    );
}

export function CostingPage3({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Other Project Expenses</h5>
            <CurrencyField name="OtherProjectExpenses" label="Other Project Expenses" size="md" value={form.OtherProjectExpenses} setValue={setForm} onBlur={triggerCalculations} autoFocus />
            <InputDescription text="Enter any additional expenses not covered by other categories, such as legal fees, insurance, or other project-related costs. This amount should reflect all miscellaneous expenses associated with the venture." />

            <h5 className="col-span-2 border-b-2 font-semibold">Deal Expenses</h5>
            <PercentField name="ProjectManagementRate" label="Project Management Rate %" size="md" value={form.ProjectManagementRate} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the percentage of the project's gross revenue that will be allocated to project management expenses. This rate covers the cost of managing the project and overseeing its successful completion." />
            <PercentField name="RateForInvestorCash" label="Rate For Investor Cash %" size="md" value={form.RateForInvestorCash} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Specify the percentage of the project's gross revenue that will be paid to the investor as a return on their cash investment. This rate reflects the return on the investor's capital." />
        </div>
    );
}

export function CapitalPage({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Capital Stack</h5>
            <CurrencyField name="InvestorCash" label="Investor Cash" size="md" value={form.InvestorCash} setValue={setForm} onBlur={triggerCalculations} autoFocus />
            <InputDescription text="Input the total amount of cash the investor is contributing to the project. This represents the investor's financial commitment to the venture." />
            <CurrencyField name="BuilderCash" label="Builder Cash" size="md" value={form.BuilderCash} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Specify the total amount of cash the builder is contributing to the project. This represents the builder's financial stake in the venture." />
            <PercentField name="MinimumIRR" label="Minimum IRR" size="md" value={form.MinimumIRR} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the minimum internal rate of return (IRR) expected for the project. This is the lowest return rate that the project needs to achieve to be considered viable or attractive to investors." />
            <PercentField name="MaximumIRR" label="Maximum IRR" size="md" value={form.MaximumIRR} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Input the maximum internal rate of return (IRR) anticipated for the project. This represents the highest return rate expected, reflecting the best-case financial outcome of the venture." />

        </div>
    );
}

export function CompletePage() {
    return (
        <>
            <div className="flex flex-grow flex-col flex-wrap text-wrap p-5 ">
                <p className="m-auto"> Congratulations, you are done!</p>
            </div>
        </>
    )
}


