import { useEffect, useMemo, useState } from "react";
import APIRequest from "../../helpers/CreateRequest";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import Button from "../../components/atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { GetMonthIndexByNameSubstring } from "./GetMonth"
import { GetMonthByIndex } from "./GetMonth"

const name = {
    normal: 'Sales Plan',
    plural: 'Sales Plans',
    alias: 'Sales Plan',
    aliasPlural: 'Sales Plans',
    data: 'SalesPlan',
    dataPlural: 'SalesPlans',
    sectionTitle: 'Home Building'
}

export const fields: field[] = [
    { name: 'SalesPlanName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'md' },
    { name: 'Description', type: 'text', size: 'md', },
    { name: 'Code', type: 'text', required: true, size: 'md' },
    { name: 'UnitTypeID', type: 'select', size: 'md', alias: 'Unit Type', required: true, SelectElement: { name: 'UnitType', namePlural: 'UnitTypes', staticOptions: [{ name: 'Attached', value: '1' }, { name: 'Detached', value: '2' }] } },
    { name: 'CommunityID', type: 'select', SelectElement: { name: 'Community', namePlural: 'Communities' }, required: true, size: 'md', alias: 'Community', SpecialField: 'SalesPlanCommunityID' },
    { name: 'UnitsRemaining', alias: 'Units remaining', type: 'int', size: 'md', readonly: true, SpecialField: 'SalesPlansUnitsRemaining' },


    { name: 'YearOne', alias: 'Year', type: 'int', size: 'md', group: 'Year 1', required: true, SpecialField: 'SalesPlansYear', readonly: true },
    { name: 'YearOneJanuaryUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneFebruaryUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneMarchUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneAprilUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneMayUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneJuneUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneJulyUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneAugustUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneSeptemberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneOctoberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneNovemberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearOneDecemberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 1', SpecialField: 'SalesPlansEstimatedUnitsSold' },

    { name: 'YearTwo', alias: 'Year', type: 'int', size: 'md', group: 'Year 2', required: true, SpecialField: 'SalesPlansYear', readonly: true },
    { name: 'YearTwoJanuaryUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoFebruaryUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoMarchUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoAprilUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoMayUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoJuneUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoJulyUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoAugustUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoSeptemberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoOctoberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoNovemberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },
    { name: 'YearTwoDecemberUnitCount', alias: 'Units', type: 'int', size: 'md', group: 'Year 2', SpecialField: 'SalesPlansEstimatedUnitsSold' },

    { name: "Override", type: 'text', size: 'md', hidden: true },
    { name: "UnitsRemainingOriginal", type: 'text', size: 'md', hidden: true },
    { name: "CurrentMonthActualSalesUnitCount", type: 'text', size: 'md', hidden: true },
]


export default function SalesPlans() {
    const location = useLocation()
    const stateID = location.state?.id || undefined
    const [obj, setObj] = useState<any>()
    const [objID, setObjID] = useState(stateID)
    const [mode, setMode] = useState<mode>('view')
    const [refresh, setRefresh] = useState(false)
    const emptySet: FieldValues = useMemo(() => Object.fromEntries(fields.map(field => [field.sendAs || field.name, null])), [])

    //Get object when the id changes or a refresh is called 
    useEffect(() => {
        const getObj = async () => {
            const obj = await GetObj(objID, name.dataPlural, fields)
            if (obj !== -1) {
                obj.UnitsRemainingOriginal = obj.UnitsRemaining 
                obj.UnitsRemaining = obj.UnitsRemainingOriginal - DetermineUnitsRemaining(obj)
                setObj(obj)
                setMode('view')
            }
        }
        if (objID) getObj()
    }, [objID, refresh])

    function DetermineUnitsRemaining(obj: any) {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()

        let unitsUsed = 0

        fields.forEach(field => {

            if (field.name.includes("UnitCount") && !field.name.includes("CurrentMonthActualSalesUnitCount")) {
                const fieldMonthNumber = GetMonthIndexByNameSubstring(field.name)
                const fieldYearNumber = Number(field.name.includes('YearOne') ? obj['YearOne'] : obj['YearTwo'])

                if (
                    (fieldYearNumber === currentYear && (fieldMonthNumber) >= currentMonth) ||
                    fieldYearNumber > currentYear
                ) {
                    if (fieldMonthNumber === currentMonth && fieldYearNumber === currentYear && obj.CurrentMonthActualSalesUnitCount) {
                        unitsUsed = unitsUsed + obj[field.name] - obj.CurrentMonthActualSalesUnitCount;
                    } else {
                        unitsUsed = unitsUsed + obj[field.name];
                    }
                }

            }
        })
        return unitsUsed
    }

    return (
        <>
            <div className="py-10 px-10 h-full overflow-y-auto">

                <CRUD
                    name={name}
                    fields={fields}
                    obj={obj}
                    id={objID}
                    setID={setObjID}
                    mode={mode}
                    setMode={setMode}
                    emptySet={emptySet}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />

                {/* Details or sections */}
                <div className="flex-grow w-full m-auto"></div>
            </div>
        </>
    )
}
